import styled from "styled-components";
import { ClickableComponent, Div } from "components";
import CurrencyInput from "react-currency-input";

export const Wrapper = styled(Div)`
  display: flex;
  width: 100%;
  padding: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
`;

export const LeftContainer = styled(Div)`
  display: flex;
  width: 50%;
`;

export const RightContainer = styled(Div)`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledStrong = styled.strong`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 6px;
`;

export const StyledButton = styled(ClickableComponent)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100px;
  margin-right: 8px;
`;
