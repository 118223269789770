import React from "react";
import { Wrapper } from "./styles";

const Footer = ({ children, className, style }) => (
  <Wrapper className={className} style={style}>
    {children}
  </Wrapper>
);

export default Footer;
