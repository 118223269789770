import React, { useState, useEffect } from "react";

import { useMyContext } from "hooks";
import { usePeriodRange } from "../../../state";
import Utils from "utils/utils";
import { MobileFooter } from "components";
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  StyledText,
  StyledSpan,
  StyledSelect,
  PeriodList,
  PeriodItem,
} from "./styles";

const Period = ({
  selectedPeriod,
  periods,
  setSelectedPeriod,
  selectedTab,
}) => {
  const { t } = useMyContext();
  const { formatPeriod } = Utils();
  const [isOpenModal, setIsOpenModal] = useState();
  const [periodRange] = usePeriodRange();
  const [periodString, setPeriodString] = useState(t["period:calc"]);

  useEffect(() => {
    const { initialDate, finalDate } = periodRange;
    setPeriodString(
      initialDate && finalDate ? formatPeriod(finalDate, initialDate) : ""
    );
  }, [periodRange, selectedTab]);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsOpenModal(true);
  };

  const handlePeriodClick = (period) => {
    setIsOpenModal(false);
    setSelectedPeriod(period);
  };

  return (
    <>
      <Wrapper>
        <LeftContainer>
          <StyledText bold>{t["molecules:ThirdHeader:periodo"]}</StyledText>
          <StyledSpan>{periodString}</StyledSpan>
        </LeftContainer>
        <RightContainer>
          <StyledSelect defaultValue={0} onMouseDown={handleMouseDown}>
            <option disabled={true} value={0}>
              {t[`period:${selectedPeriod}`]}
            </option>
          </StyledSelect>
        </RightContainer>
      </Wrapper>
      <MobileFooter
        body={
          <PeriodList>
            {!!periods &&
              periods.map((period) => (
                <PeriodItem
                  key={period}
                  onClick={() => handlePeriodClick(period)}
                >
                  {t[`period:${period}`]}
                </PeriodItem>
              ))}
          </PeriodList>
        }
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default Period;
