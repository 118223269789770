import React from "react";
import { Wrapper, Spinner as Sp } from "./styles";

const Spinner = ({ style, size, color }) => {
  return (
    <Wrapper style={style}>
      <Sp size={size} color={color} />
    </Wrapper>
  );
};

export default Spinner;
