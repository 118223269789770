import React, { useState } from "react";

import { Wrapper, HeaderContainer, BodyContainer } from "./styles";
import { MdAddBox, MdIndeterminateCheckBox } from "react-icons/md";

const Acordion = ({ className, children, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleOpenCloseAcordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper className={className}>
      <HeaderContainer>
        {title}
        {isOpen ? (
          <MdIndeterminateCheckBox
            size={24}
            onClick={handleOpenCloseAcordion}
          />
        ) : (
          <MdAddBox size={24} onClick={handleOpenCloseAcordion} />
        )}
      </HeaderContainer>
      <BodyContainer isOpen={isOpen}>{children}</BodyContainer>
    </Wrapper>
  );
};

export default Acordion;
