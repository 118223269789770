import styled from "styled-components";
import { Div, Header } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media (max-width: 920px) {
    background-color: ${({ theme }) =>
      theme.colors[theme.background.quaternary.color][
        [theme.background.quaternary.intensity]
      ]};
  }
`;

export const StyledHeader = styled(Header)`
  @media (max-width: 920px) {
    justify-content: center;
    background-color: ${({ theme }) =>
      theme.colors[theme.background.sextenary.color][
        [theme.background.sextenary.intensity]
      ]};
  }
`;

export const ContentWrapper = styled.section`
  min-height: calc(100vh - 177px);
  display: flex;
  flex-direction: column;
  margin-top: 81px;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 920px) {
    margin-top: 50px;
    background-color: ${({ theme }) =>
      theme.colors[theme.background.quaternary.color][
        [theme.background.quaternary.intensity]
      ]};
  }
`;
