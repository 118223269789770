import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useMyContext } from "hooks";
import alertImage from "assets/alert.svg";

import {
  ModalContainer,
  Header,
  AlertText,
  Body,
  Footer,
  StyledImg,
} from "./styles";
import {
  useFetchingAssetsSeries,
  useSelectAssetsWithoutSeries,
} from "../../../../state";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const SerieModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [assetsWithoutSeries] = useSelectAssetsWithoutSeries();
  const [isFetchingAssetsSeries] = useFetchingAssetsSeries();
  const { t } = useMyContext();

  useEffect(() => {
    if (assetsWithoutSeries.length > 0 && !isFetchingAssetsSeries) {
      setIsOpen(true);
    }
  }, [assetsWithoutSeries, isFetchingAssetsSeries]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
    >
      <ModalContainer>
        <Header>
          <StyledImg src={alertImage} />
          <AlertText>{t["molecules:Modals:SerieModal:alert"]}</AlertText>
        </Header>
        <Body>
          {assetsWithoutSeries.map(({ label }) => (
            <span>{label}</span>
          ))}
        </Body>
        <Footer>
          <button onClick={() => setIsOpen(false)}>OK</button>
        </Footer>
      </ModalContainer>
    </Modal>
  );
};

export default SerieModal;
