import React from "react";
import PropTypes from "prop-types";
import { Icon, Spinner } from "components";
import { Wrapper, StyledInput, StyledLeftIcon } from "./styles";

const Textfield = ({
  id,
  className,
  type,
  fullWidth,
  required,
  reference,
  status,
  leftIconName,
  maxLength,
  placeholder,
  style,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  hasLoading,
  disabled,
  onCancel,
}) => {
  const hasLeftIcon = !!leftIconName;
  return (
    <Wrapper
      id={`textfieldwrapper-${id}`}
      className={className}
      fullWidth={fullWidth}
      style={style}
    >
      {leftIconName && <StyledLeftIcon iconname={leftIconName} iconsize="sm" />}
      <StyledInput
        id={id}
        fullWidth={fullWidth}
        type={type}
        required={required}
        ref={reference}
        status={status}
        hasLeftIcon={hasLeftIcon}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
      {hasLoading && <Spinner style={{ marginRight: "5px" }} />}
      {onCancel && (
        <Icon
          iconname="close"
          style={{ marginRight: "5px" }}
          onClick={onCancel}
          width="1.2rem"
          height="1.2rem"
        />
      )}
    </Wrapper>
  );
};

Textfield.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  helper: PropTypes.string,
  type: PropTypes.oneOf(["text", "password"]).isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  status: PropTypes.oneOf(["error", "success"]),
  leftIconName: Icon.propTypes.iconname,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Textfield;
