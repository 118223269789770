import React, { useEffect, useState } from "react";
import { useCorrelationData } from "state";
import * as Highcharts from "highcharts";
import Heatmap from "highcharts/modules/heatmap";
import defaultTheme from "themes/Themes/clients/defaultTheme";
import { useMyContext } from "hooks";
import {
  Chart,
  AssetsList,
  AssetsItem,
  Content,
  Legend,
  RightContent,
  Title,
  Spacer,
} from "./styles";
import {
  RED_CORRELATION_GRAPH,
  ORANGE_CORRELATION_GRAPH,
  YELLOW_CORRELATION_GRAPH,
  BLUE_CORRELATION_GRAPH,
  GREEN_CORRELATION_GRAPH,
  BLUE_QUANTUM,
} from "models/constants";
import { createQuantumApi } from "api";
import { append, drop } from "ramda";
import Utils from "utils/utils";

const quantumApi = createQuantumApi();

Heatmap(Highcharts);

const HigchartsCorrelation = ({
  assets,
  selectedTab,
  selectedPeriod,
  selectedBenchmarksIndexes,
}) => {
  const { innerWidth } = window;
  const [windowSize, setWindowSize] = useState(innerWidth);

  const [correlationData, setCorrelationData] = useCorrelationData();
  const { themeType, t } = useMyContext();
  const theme = defaultTheme[themeType];
  const { formatPeriod } = Utils();

  useEffect(() => {
    const loadCorrelationData = async () => {
      const periodRange = await quantumApi.fetchPeriodRange(
        selectedPeriod,
        assets
      );
      const correlationData = await quantumApi.fetchCorrelationData(
        periodRange,
        assets
      );
      setCorrelationData(correlationData);
    };
    try {
      loadCorrelationData();
    } catch (err) {
      console.log("Erro carregando correlação:", err);
    }
  }, []);

  const getPointCategoryName = (point, dimension) => {
    var series = point.series,
      isY = dimension === "y",
      axis = series[isY ? "yAxis" : "xAxis"];
    return axis.categories[point[isY ? "y" : "x"]];
  };

  Highcharts.setOptions({
    lang: {
      resetZoom: t["chart:resetZoom"],
    },
  });

  const highcharts = Highcharts.chart("line", {
    chart: {
      width: Math.max(200, assets.length * 50 + 100),
      height: Math.max(200, assets.length * 50 + 100) + 60,
      type: "heatmap",
      marginTop: 40,
      marginBottom: 40,
      plotBorderWidth: 0,
      borderWidth: 0,
      borderColor: "white",
      style: {
        fontFamily: theme.fonts.primary,
      },
      zoomType: "x",
      backgroundColor:
        windowSize <= 920
          ? theme.colors[theme.background.quaternary.color][
              [theme.background.quaternary.intensity]
            ]
          : "white",
    },
    credits: {
      enabled: false,
    },
    colors: ["white"],

    title: {
      enabled: false,
      align: "left",
      text: "",
    },

    xAxis: {
      visible: false,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },

    yAxis: {
      labels: {
        enabled: false,
      },
      title: "Ativos",
      reversed: true,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },

    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, "x"),
            yName = getPointCategoryName(point, "y"),
            val = point.value;
          return ix + ". " + xName + " sales " + yName + ", " + val + ".";
        },
      },
    },

    colorAxis: {
      min: -100,
      max: 100,
      // dataClassColor: "category",
      stops: [
        [0, RED_CORRELATION_GRAPH],
        // [0.125, RED_CORRELATION_GRAPH],
        [0.25, ORANGE_CORRELATION_GRAPH],
        // [0.4, ORANGE_CORRELATION_GRAPH],
        [0.5, YELLOW_CORRELATION_GRAPH],
        // [0.6, YELLOW_CORRELATION_GRAPH],
        [0.75, GREEN_CORRELATION_GRAPH],
        // [0.875, GREEN_CORRELATION_GRAPH],
        // [0.875, BLUE_CORRELATION_GRAPH],
        [1, BLUE_CORRELATION_GRAPH],
      ],
      labels: {
        format: "{value}",
      },
      tickPositioner: () => [-100, -50, 0, 50, 100],
      borderColor: "#000000",
    },

    legend: {
      enabled: true,
      align: "center",
      layout: "horizontal",
      verticalAlign: "bottom",
      itemWidth: null,
      width: null,
      symbolWidth: Math.max(200, assets.length * 50 + 100) - 20,
      y: 20,
    },

    tooltip: {
      backgroundColor: "white",
      useHTML: true,
      formatter: function () {
        if (this.point.x <= this.point.y && this.point.x !== 0) {
          return (
            '<div style="display: flex; background-color: white;  align-items: center;"><div style="font-weight: 800; font-size: 20px; padding-right: 10px; align-items: center; justify-content: center; border-right: 2px solid black; ">' +
            this.point.value +
            "</div>" +
            '<div style="display: flex; flex-direction: column; margin-left: 10px; font-family: Open Sans; font-style: normal; font-weight: 600; color: #333333; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; justify-content: start;">' +
            `<div style="font-size: 14px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${
              this.point.x
            } - ${assets[this.point.x - 1].label}</div>` +
            `<div style="font-size: 14px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${
              this.point.y
            } - ${assets[this.point.y - 1].label}</div>` +
            "</div" +
            "</div>"
          );
        } else {
          return `<div style="font-family: Open Sans; font-style: normal; font-weight: 600; color: #333333; font-size: 14px; overflow: hidden;  white-space: nowrap; text-overflow: ellipsis;">${
            this.point.value
          } - ${assets[this.point.value - 1].label}</div>`;
        }
      },
    },

    series: [
      {
        borderRadius: 10,
        name: "Correlação entre ativos",
        borderWidth: 1.5,
        data: correlationData?.correlation?.flatMap((asset, row) =>
          append(
            {
              value: row + 1,
              x: row + 1,
              y: row,
              dataLabels: {
                enabled: true,
                color: "#FFFFFF",
                style: {
                  fontSize: Math.max(14, 16 - assets.length * 2),
                },
              },
              color: BLUE_QUANTUM,
            },
            append(
              {
                value: row + 1,
                x: 0,
                y: row + 1,
                dataLabels: {
                  enabled: true,
                  color: "#FFFFFF",
                  style: {
                    fontSize: Math.max(14, 16 - assets.length * 2),
                  },
                },
                color: BLUE_QUANTUM,
              },
              drop(
                row,
                asset[1].map((correlation, j) => ({
                  value: correlation === null ? "N/A" : parseInt(correlation),
                  x: row + 1,
                  y: j + 1,
                  dataLabels: {
                    style: {
                      fontSize: Math.max(14, 16 - assets.length * 2),
                    },
                    enabled: true,
                    color: "#000000",
                  },
                }))
              )
            )
          )
        ),

        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
        },
      },
    ],
  });

  useEffect(() => {
    const loadCorrelationData = async () => {
      const periodRange = await quantumApi.fetchPeriodRange(
        selectedPeriod,
        assets
      );
      const correlationData = await quantumApi.fetchCorrelationData(
        periodRange,
        assets
      );
      setCorrelationData(correlationData);
    };
    try {
      loadCorrelationData();
    } catch (err) {
      console.log(err);
    }
  }, [selectedBenchmarksIndexes]);

  // TODO:
  // OBS.: Por toda a aplicação foi verificado o innerWidth para tentar dar uma responsividade,
  // mas da forma com que isso foi feito, não ocorre uma releitura da informação, somente se recarregar
  // a página.
  // Essa talvez seja uma solução. Se estiver OK, inserir no início do App e criar uma entrada no
  // redux para manter esse valor.
  // Então deve-se alterar todo o app onde o innerWidth é utilizado.

  return (
    <Content
      style={{
        backgroundColor: windowSize <= 920 ? "none" : "white",
      }}
    >
      <Legend>
        <Title>
          {correlationData?.correlation
            ? `${t["tabs:Correlation"]} - ${formatPeriod(
                correlationData.finalDate,
                correlationData.initialDate
              )}
                  `
            : ""}
        </Title>
        <AssetsList>
          <Spacer key={1000}>
            <span>{t["common:legend"]}</span>
          </Spacer>
          {assets.map((asset, index) => (
            <AssetsItem key={index}>
              {index + 1} - {asset.label}
            </AssetsItem>
          ))}
        </AssetsList>
      </Legend>
      <RightContent>
        <Chart
          id="line"
          style={{
            backgroundColor: windowSize <= 920 ? "none" : "white",
          }}
        />
      </RightContent>
    </Content>
  );
};

export default HigchartsCorrelation;
