import React, { useState, useEffect } from "react";

import { Icon } from "components";
import { useMyContext } from "hooks";
import Utils from "utils/utils";
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  StyledSpan,
  StyledStrong,
  StyledButton,
  StyledCurrencyInput,
} from "./styles";

import { useSelectInicialApplication } from "../../../state";

const InitialApplication = () => {
  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();

  const { t } = useMyContext();
  const { formatCurrency, currencyFormatOptions } = Utils();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(initialApplication);
  const MIN_APPLICATION = 1;
  const MAX_APPLICATION = 1000000000;

  const handleChange = (event, _, floatvalue) => {
    event.preventDefault();
    if (floatvalue > MAX_APPLICATION) {
      setInputValue(MAX_APPLICATION);
      setInitialApplication(MAX_APPLICATION);
    } else {
      setInitialApplication(floatvalue);
      setInputValue(floatvalue);
    }
  };

  const handleEditClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      if (initialApplication !== inputValue) {
        if (inputValue < MIN_APPLICATION) {
          setInputValue(MIN_APPLICATION);
          setInitialApplication(MIN_APPLICATION);
        } else if (inputValue > MAX_APPLICATION) {
          setInputValue(MAX_APPLICATION);
          setInitialApplication(MAX_APPLICATION);
        } else {
          setInitialApplication(inputValue);
        }
      }
      setIsEditing(false);
    }
  };

  return (
    <Wrapper>
      <LeftContainer>
        <StyledStrong>{t["molecules:ThirdHeader:aplicaoInicial"]}</StyledStrong>
      </LeftContainer>
      <RightContainer>
        {isEditing ? (
          <StyledCurrencyInput
            value={inputValue}
            decimalSeparator={currencyFormatOptions.decimalSeparator}
            thousandSeparator={currencyFormatOptions.thousandSeparator}
            prefix={currencyFormatOptions.currencySymbol}
            allowNegative={false}
            onChangeEvent={handleChange}
            maxLength={17}
          />
        ) : (
          <StyledSpan>{formatCurrency(initialApplication)}</StyledSpan>
        )}
        <StyledButton onClick={handleEditClick}>
          <Icon iconname="edit" size="18" />
        </StyledButton>
      </RightContainer>
    </Wrapper>
  );
};

export default InitialApplication;
