import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";

const Li = ({ children, index, isSelected, onClick, className }) => {
  return (
    <Wrapper
      data-index={index}
      className={className}
      isSelected={isSelected}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

Li.prototypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export default Li;
