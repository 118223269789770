import logo from "assets/logo-oui-investimentos.png";

const genericDesign = {
  backgroundImages: [],
  fonts: {
    primary: "Open Sans, sans-serif",
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
    quote: "Georgia, serif",
    size: {
      sm: "12px",
      md: "14px",
      lg: "16px",
    },
    lineHeight: ["40px", "16px", "24px", "14px"],
    weight: ["400", "700", "600"],
  },
  colors: {
    white: [
      "#FFFFFF",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
    ],
    black: [
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
    ],
    gray: ["#333333", "#666666", "#F2F1F6", "#dce2eb"],
    red: ["#DF0B0B"],
    blue: [
      "#364E63",
      "#364E63",
      "#254057",
      "#3A5267",
      "#27618F",
      "#08263F",
      "#526FC7",
    ],
  },
  sizes: {
    pagesBreakpoints: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xlg: "1200px",
    },
    maxWidth: "1100px",
    title: {
      size: ["32px", "18px"],
      lineHeight: ["40px", "30px"],
      weight: ["400", "700"],
    },
    button: {
      sm: {
        padding: "8px 16px 8px 16px",
        justIconPadding: "8px",
        size: "12px",
        radius: "12px",
        width: "67px",
        height: "32px",
        lineHeight: "16px",
        letterSpacing: "0.04em",
      },
      md: {
        padding: "16px",
        justIconPadding: "14px",
        size: "14px",
        radius: "16px",
        width: "86px",
        height: "48px",
        lineHeight: "16px",
        letterSpacing: "0.02em",
      },
      lg: {
        padding: "16px 24px 16px 24px",
        justIconPadding: "18px 26px 18px 26px",
        size: "16px",
        radius: "16px",
        width: "93px",
        height: "56px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
      },
    },
    icon: {
      xlg: "3em",
      lg: "2.5em",
      md: "2em",
      sm: "1.5em",
      xsm: "1em",
    },
    spacing: [
      "0px",
      "8px",
      "16px",
      "24px",
      "32px",
      "40px",
      "48px",
      "56px",
      "64px",
      "72px",
      "80px",
    ],
  },
};

const dark = {
  logo: {
    img: logo,
    height: "78px",
    padding: "10px 0px",
    align: "center",
    show: false,
  },
  primary: [5, 7, 9],
  borders: [0],
  text: [0, 1],
  textColors: {
    primary: {
      color: "white",
      intensity: 0,
    },
    secondary: {
      color: "gray",
      intensity: 0,
    },
    error: { color: "red", intensity: 0 },
  },
  defaulTextColor: "black",
  header: {
    align: "flex-start",
    first: {
      showBorder: false,
    },
    second: {
      margin: "0",
    },
    third: {
      margin: "0",
    },
  },
  background: {
    primary: { color: "gray", intensity: 1 },
    secondary: { color: "white", intensity: 0 },
    tertiary: { color: "blue", intensity: 2 },
    quaternary: { color: "blue", intensity: 5 },
    quintenary: { color: "gray", intensity: 3 },
    sextenary: { color: "blue", intensity: 2 },
  },
  iconColor: {
    primary: {
      color: "blue",
      intensity: 4,
    },
  },
  input: {
    placeholder: {
      primary: {
        color: "gray",
        intensity: 0,
      },
    },
    size: ["16px"],
    lineHeight: ["24px"],
    weight: ["400"],
    background: { color: "blue", intensity: 1 },
    textcolor: {
      color: "gray",
      intensity: 0,
    },
    bordercolors: {
      primary: {
        color: "gray",
        intensity: 8,
      },
      hover: {
        color: "blue",
        intensity: 4,
      },
      focus: {
        color: "blue",
        intensity: 4,
      },
      error: {
        color: "red",
        intensity: 2,
      },
      success: {
        color: "gray",
        intensity: 0,
      },
      disabled: {
        color: "gray",
        intensity: 3,
      },
    },
  },
  pages: {
    graphic: {
      paddingTop: 0,
    },
  },
  searchAssets: {
    width: "100%",
    padding: "24px 16px 0 16px",
    marginTop: 0,
    marginLeft: 0,
    listResult: {
      width: "100%",
    },
  },
  selectedAssets: {
    show: false,
    viewButton: true,
  },
  showAssetFullName: true,
};

const light = {
  logo: {
    img: logo,
    height: "58px",
    padding: "0",
    align: "flex-start",
    show: false,
  },
  primary: [5, 7, 9],
  borders: [0],
  text: [0, 1],
  textColors: {
    primary: {
      color: "gray",
      intensity: 0,
    },
    secondary: { color: "white", intensity: 1 },
    error: { color: "red", intensity: 0 },
  },
  defaulTextColor: "black",
  background: {
    primary: { color: "gray", intensity: 1 },
    secondary: { color: "white", intensity: 0 },
    tertiary: { color: "blue", intensity: 6 },
    quaternary: { color: "gray", intensity: 2 },
    quintenary: { color: "gray", intensity: 3 },
    sextenary: { color: "white", intensity: 0 },
  },
  header: {
    align: "center",
    first: {
      showBorder: false,
    },
    second: {
      margin: "16px 0 3px",
    },
    third: {
      margin: "0 0 16px",
    },
  },
  iconColor: {
    primary: {
      color: "blue",
      intensity: 4,
    },
  },
  input: {
    placeholder: {
      primary: {
        color: "gray",
        intensity: 0,
      },
    },
    size: ["16px"],
    lineHeight: ["24px"],
    weight: ["400"],
    background: { color: "blue", intensity: 1 },
    textcolor: {
      color: "gray",
      intensity: 0,
    },
    bordercolors: {
      primary: {
        color: "gray",
        intensity: 8,
      },
      hover: {
        color: "blue",
        intensity: 4,
      },
      focus: {
        color: "blue",
        intensity: 4,
      },
      error: {
        color: "red",
        intensity: 2,
      },
      success: {
        color: "gray",
        intensity: 0,
      },
      disabled: {
        color: "gray",
        intensity: 3,
      },
    },
  },
  pages: {
    graphic: {
      paddingTop: "16px",
    },
  },
  searchAssets: {
    width: "60%",
    padding: 0,
    marginTop: 0,
    marginLeft: 0,
    listResult: {
      width: "100%",
    },
  },
  selectedAssets: {
    show: true,
    viewButton: false,
  },
  showAssetFullName: true,
};

const OuiTheme = {
  dark: {
    ...dark,
    ...genericDesign,
  },
  light: {
    ...light,
    ...genericDesign,
  },
};

export default OuiTheme;
