import React, { useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, ColorFlag, StyledText, StyledIconButton } from "./styles";
import colorFlag from "assets/colorFlag.svg";

const Chip = ({ id, className, item, deleteChip, showFullName }) => {
  const [hidden, setHidden] = useState(true);

  const hadleOnMouseEnter = () => {
    setHidden(false);
  };

  const hadleOnMouseLeave = () => {
    setHidden(true);
  };

  return (
    <Wrapper
      id={id}
      className={className}
      onMouseEnter={hadleOnMouseEnter}
      onMouseLeave={hadleOnMouseLeave}
      showFullName={showFullName}
    >
      <ColorFlag src={colorFlag} color={item.color} />
      <StyledText>{item.label}</StyledText>
      <StyledIconButton
        hidden={hidden}
        type="button"
        size="md"
        iconname="close"
        bgColor="white"
        onClick={() => deleteChip(item.identifier)}
      />
    </Wrapper>
  );
};

Chip.propTypes = {
  id: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  deleteChip: PropTypes.func,
  showFullName: PropTypes.bool,
};

export default Chip;
