import styled from "styled-components";
import { rgba } from "polished";
import { Div, Textfield } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ theme }) => theme.searchAssets.width};
  padding: ${({ theme }) => theme.searchAssets.padding};

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const StyledTextField = styled(Textfield)`
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.gray[theme.borders[0]] : "white"};
  padding: 12px 0;
  @media (max-width: 920px) {
    margin-top: ${({ theme }) => theme.searchAssets.marginTop};
    border: none;
    border-radius: 4px;
    background-color: white;
    width: 100%;
  }
`;

export const SearchListWrapper = styled(Div)`
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  width: ${({ theme }) => theme.searchAssets.listResult.width};
`;

export const SearchList = styled.ul`
  width: inherit;
  display: flex;
  position: absolute;
  flex-direction: column;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
  border-top: none;
  padding-bottom: 16px;
  background-color: white;
  max-height: 480px;
  overflow: auto;
`;
