import React from "react";
import Modal from "react-modal";

import { useMyContext } from "hooks";
import { Body, TopBar, ModalContainer, CloseButton, BottomBar } from "./styles";

const MobileFooter = ({ bottom, body, isOpen, onClose }) => {
  const { themeType, t } = useMyContext();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(51, 51, 51, 0.75)",
        },
        content: {
          top: "auto",
          right: 0,
          left: 0,
          padding: 0,
          bottom: 0,
        },
      }}
    >
      <ModalContainer>
        <TopBar>
          <CloseButton themeType={themeType} onClick={onClose}>
            {t["molecules:Modals:MobileFooter:close"]}
          </CloseButton>
        </TopBar>
        <Body themeType={themeType}>{body}</Body>
        {!!bottom && <BottomBar>{bottom}</BottomBar>}
      </ModalContainer>
    </Modal>
  );
};

export default MobileFooter;
