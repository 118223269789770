import { HOME_TITLE } from "environment";

const ptBR = {
  "period:to": "a",
  "period:calc": "calculando...",
  "common:asset": "Ativo",
  "commom:remove": "Remover",
  "common:return": "Retorno",
  "common:legend": "Legenda",

  "chart:months": [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  "chart:months:short": [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  "chart:resetZoom": "Cancelar zoom",
  "chart:desktop:zoom":
    "Clique e arraste na área do gráfico para aumentar o zoom",
  "chart:mobile:zoom": "Utilize 2 dedos para dar zoom",

  "molecules:TitleHome:Title:title-1":
    HOME_TITLE || "Comparador de ativos OUI Investimentos,",
  "molecules:TitleHome:Title:title-2": "mais que fundos de investimentos",
  "molecules:ThirdHeader:benchmark": "Benchmark",
  "molecules:ThirdHeader:periodo": "Período",
  "molecules:ThirdHeader:aplicaoInicial": "Aplicação Inicial",
  "molecules:ActiveBox:cancelar": "Cancelar",
  "molecules:ActiveBox:selecionar": "Selecionar",
  "molecules:Modals:SerieModal:alert":
    "Alguns ativos não serão exibidos no gráfico pois não possuem série histórica em todo esse período.",
  "molecules:Modals:MobileFooter:close": "Fechar",
  "molecules:Modals:MobileFooter:shareLinkMessage":
    "Link para compartilhamento:",
  "molecules:Modals:MobileFooter:shareQRTitle": "QR Code",
  "molecules:Modals:MobileFooter:shareQRCodeMessage":
    "Abra a câmera e aponte seu celular para essa tela para capturar o código.",
  "molecules:Modals:MobileFooter:copy": "Copiar",
  "molecules:Modals:MobileFooter:copyed": "Copiado!",
  "molecules:Card:asset": "Ativo",

  "organisms:SearchAssets:searchString":
    "Digite para buscar ativos (nome, CNPJ ou ticker)",
  "organisms:SelectedActives:title": "Ativos selecionados",
  "organisms:SearchAssets:cnpj": "CNPJ",
  "organisms:SearchAssets:isin": "ISIN",
  "organisms:SearchAssets:managementCompany": "Gestão",
  "organisms:SearchAssets:type": "Tipo",
  "organisms:SearchAssets:situation": "Situação",
  "organisms:SearchAssets:stockExchange": "Bolsa",
  "organisms:SearchAssets:nenhumResultadoEncontrado":
    "Nenhum resultado encontrado",
  "organisms:SearchHeader:viewSelectedAssets": "Ver ativos selecionados",
  "organisms:OverlayRow:remover": "Remover",

  "pages:HomePage:ClearActives": "Limpar todos",
  "pages:HomePage:AnalysisButton": "Iniciar análise dos ativos selecionados ",
  "pages:HomePage:AnalysisButton:mobile": "Iniciar análise dos ativos",
  "table:Header:Indicators": "Indicadores no período selecionado",
  "table:Header:ReturnHistory": "Histórico de Retorno",

  "tabs:Rentability": "Rentabilidade",
  "tabs:Return": "Retorno",
  "tabs:Underwater": "Underwater",
  "tabs:Correlation": "Correlação",

  "share:shareLink": "Compartilhar",
  "share:QRCode": "Gerar QR Code",
  "share:copyLink": "Copiar Link",
  "share:copiedLink": "Link Copiado!",
  "share:invalidLink": "Link Inválido!",
  "share:loadingLink": "Seu link está carregando. Por favor, aguarde.",

  "month:0": "Janeiro",
  "month:1": "Fevereiro",
  "month:2": "Março",
  "month:3": "Abril",
  "month:4": "Maio",
  "month:5": "Junho",
  "month:6": "Julho",
  "month:7": "Agosto",
  "month:8": "Setembro",
  "month:9": "Outubro",
  "month:10": "Novembro",
  "month:11": "Dezembro",

  "shortMonth:0": "Jan",
  "shortMonth:1": "Fev",
  "shortMonth:2": "Mar",
  "shortMonth:3": "Abr",
  "shortMonth:4": "Mai",
  "shortMonth:5": "Jun",
  "shortMonth:6": "Jul",
  "shortMonth:7": "Ago",
  "shortMonth:8": "Set",
  "shortMonth:9": "Out",
  "shortMonth:10": "Nov",
  "shortMonth:11": "Dez",

  "period:NO_MES": "Mês",
  "period:UM_ANO_UTIL": "1 ano",
  "period:SEIS_MESES_UTEIS": "6 meses",
  "period:CINCO_ANOS_UTEIS": "5 anos",
  "period:OTIMO": "Ótimo",

  "measure:RENTABILIDADE": "Rentabilidade",
  "measure:PATRIMONIO_LIQUIDO": "PL Milhões",
  "measure:SHARPE": "Sharpe",
  "measure:TAXA_DE_ADMINISTRACAO": "Taxa de Admin",
  "measure:VOLATILIDADE": "Volatilidade",
  "measure:RETORNO": "Retorno",
  "measure:RETORNO_UM_ANO": "Retorno 1 Ano",
  "measure:RETORNO_YTD": "Retorno YTD",
  "measure:DRAW_DOWN": "Draw Down",
  "measure:INICIO_DO_1_DRAW_DOWN": "Iní. máx. D.D.",
  "measure:DATA_DO_1_DRAW_DOWN": "Data máx. D.D.",
  "measure:PRIMEIRO_DRAW_DOWN": "Máx. D.D.",

  "assetType:FI": "FI",
  "assetType:FII": "FII",
  "assetType:EUROPA": "Europa",
  "assetType:ACAO": "Ação",
  "assetType:RENDA_FIXA": "Renda Fixa",
  "assetType:EMPRESA_ABERTA": "Empresa Aberta",
};

export default ptBR;
