import styled from "styled-components";
import { Div, Text } from "components";
import IconButton from "components/molecules/IconButton";

export const Wrapper = styled(Div)`
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors[theme.borders[0]]};
  border-radius: 8px;
  height: 40px;
  max-width: ${({ showFullName }) => (showFullName ? "100%" : "226px")};
  align-items: center;
  padding: 0 12px;
  margin-bottom: 5px;

  &:first-of-type {
    margin-left: 5px;
  }

  & + & {
    margin-left: 5px;
  }
`;

export const ColorFlag = styled(Div)`
  min-width: 4px;
  height: 16px;
  background-color: ${(props) => props.color};
`;

export const StyledIconButton = styled(IconButton)`
  border: none;
  padding: 0px;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  position: absolute;
  right: 8px;

  button:focus {
    background-color: #fff;
  }
`;

export const StyledText = styled(Text).attrs((props) => ({
  weight: props.theme.fonts.weight[2],
}))`
  line-height: 14px;
  margin-left: 8px;
  color: ${({ theme, color }) => theme.colors[color || "gray"][theme.text[0]]};
`;
