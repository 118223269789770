import styled from "styled-components";
import { Div, Text, Span } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.sextenary.color][
      [theme.background.sextenary.intensity]
    ]};
  width: 100%;
  padding: 12px 16px;
  margin-top: 24px;
`;

export const LeftContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const RightContainer = styled(Div)`
  display: flex;
  width: 50%;
`;

export const StyledSelect = styled.select`
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.secondary.color][
      theme.background.secondary.intensity
    ]};
  border: none;
  border-radius: 2px;
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
  text-indent: 4px;
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 16px;
`;

export const StyledSpan = styled(Span)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const PeriodList = styled.ul`
  list-style-type: none;
`;

export const PeriodItem = styled.li`
  & + & {
    margin-top: 24px;
  }
`;
