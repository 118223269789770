import React from "react";
import PropTypes from "prop-types";
import {
  FaPowerOff,
  FaTrashAlt,
  FaAnchor,
  FaSearch,
  FaArrowAltCircleRight,
  FaRegUserCircle,
  FaRegEnvelope,
  FaRegIdCard,
  FaLock,
  FaExclamationTriangle,
  FaEdit,
} from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Img } from "components";
import closeIcon from "assets/closeIcon.svg";

const Icon = ({
  iconname,
  className,
  color,
  size,
  style,
  onClick,
  width,
  height,
}) => {
  return (
    {
      anchor: <FaAnchor id="icon" className={className} color={color} />,
      arrowAltCircleRight: (
        <FaArrowAltCircleRight id="icon" className={className} color={color} />
      ),
      arrowRight: (
        <IoIosArrowRoundForward id="icon" className={className} color={color} />
      ),
      id: <FaRegIdCard id="icon" className={className} color={color} />,
      lock: <FaLock id="icon" className={className} color={color} />,
      mail: <FaRegEnvelope id="icon" className={className} color={color} />,
      power: <FaPowerOff id="icon" className={className} color={color} />,
      userCircle: (
        <FaRegUserCircle id="icon" className={className} color={color} />
      ),
      warning: (
        <FaExclamationTriangle id="icon" className={className} color={color} />
      ),
      search: <FaSearch id="icon" className={className} color={color} />,
      close: (
        <Img
          src={closeIcon}
          className={className}
          style={style}
          onClick={onClick}
          width={width}
          height={height}
        />
      ),
      delete: (
        <FaTrashAlt id="icon" className={className} color={color} size={size} />
      ),
      edit: (
        <FaEdit id="icon" className={className} color={color} size={size} />
      ),
    }[iconname] || <div />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  iconname: PropTypes.oneOf([
    "power",
    "anchor",
    "arrowAltCircleRight",
    "userCircle",
    "mail",
    "id",
    "lock",
    "warning",
    "arrowRight",
    "search",
    "close",
    "delete",
    "edit",
  ]),
  color: PropTypes.string,
};

export default Icon;
