import React from "react";

import Modal from "react-modal";
import { useMyContext } from "hooks";
import { Body, ModalContainer, CloseButton, StyledImg } from "./styles";

import CloseIcon from "../../../../assets/closeIconWhite.svg";

const LinkErrorModal = ({ body, isOpen, onClose }) => {
  const { themeType } = useMyContext();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      onClick={handleClose}
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          backgroundColor: "rgba(51, 51, 51, 0.35)",
        },
        content: {
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
          width: "215px",
          top: "19px",
          left: "auto",
          border: "0",
          right: "8px",
          backgroundColor: "rgba(51, 51, 51, 0)",
        },
      }}
    >
      <ModalContainer>
        <CloseButton themeType={themeType} onClick={handleClose}>
          <StyledImg src={CloseIcon} height="18px" width="18px" />
        </CloseButton>
        <Body themeType={themeType}>{body}</Body>
      </ModalContainer>
    </Modal>
  );
};

export default LinkErrorModal;
