import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "i18n";
import { GlobalStyles, ThemeProvider } from "themes";
import AssetsProvider from "hooks/AssetsProvider";
import { createLogger as createReduxLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import { reducer } from "./state";
import { Provider as ReduxProvider } from "react-redux";
import { REDUX_LOGGER } from "environment";

const loggerMiddleware = createReduxLogger({
  collapsed: true,
  duration: true,
  diff: false,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const middleware = REDUX_LOGGER
  ? composeEnhancers(applyMiddleware(loggerMiddleware))
  : undefined;

const store = createStore(reducer, middleware);

const Providers = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <GlobalStyles />
      <LanguageProvider>
        <ThemeProvider>
          <AssetsProvider>
            <BrowserRouter>{children}</BrowserRouter>
          </AssetsProvider>
        </ThemeProvider>
      </LanguageProvider>
    </ReduxProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.any,
};
export default Providers;
