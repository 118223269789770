import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Footer } from "components";
import { useMyContext } from "hooks";
import {
  Content,
  FirstHeader,
  SecondHeader,
  ThirdHeader,
  Wrapper,
} from "./styles";

const GraphicTemplate = ({
  header,
  secondHeader,
  thirdHeader,
  footer,
  children,
}) => {
  const { theme, setThemeType } = useMyContext();
  const { innerWidth } = window;

  useEffect(() => {
    if (innerWidth <= 920) {
      setThemeType("dark");
    }
  }, []);

  return (
    <Wrapper id="graphic-teamplate">
      <FirstHeader showBorder={theme.header.first.showBorder}>
        {header}
      </FirstHeader>

      <SecondHeader bgColor="white" showBorder={false}>
        {secondHeader}
      </SecondHeader>

      <ThirdHeader bgColor="white" showBorder={false}>
        {thirdHeader}
      </ThirdHeader>

      <Content>{children}</Content>

      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

GraphicTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  secondHeader: PropTypes.node,
};

export default GraphicTemplate;
