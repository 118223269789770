import styled from "styled-components";
import { Div, Img } from "components";
import Item from "../NavItem";

export const Wrapper = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding: 24px 0;

  @media (max-width: 920px) {
    padding-top: 0;
    padding-bottom: 12px;
  }
`;

export const NavLeft = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};

  @media (max-width: 920px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    padding-bottom: 12px;
    border: none;
    overflow-x: auto;

    /* CSS para forçar scroll a apararecer sempre no modo mobile */
    /* ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      border: 1px solid #ffffff;
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #ffffff;
    } */
  }
`;

export const NavItem = styled(Item)`
  border: ${({ hasBorder, theme }) =>
    !!hasBorder &&
    `1px solid ${
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]
    }`};
  padding: ${({ hasBorder }) => !!hasBorder && "10px 14px"};
  border-radius: ${({ hasBorder }) => !!hasBorder && "4px"};
  font-size: 16px;
  & + & {
    margin-left: ${({ hasBorder }) => (!!hasBorder ? "12px" : "0px")};
  }

  @media (max-width: 920px) {
    border: 1px solid white;
    border-radius: 2px;
    & + & {
      margin-left: 12px;
    }
  }
`;

export const StyledImg = styled(Img)`
  margin-right: 8px;
  margin-left: 0;
`;
