import React from "react";

import { ColorPicker } from "components";
import { Wrapper, Span } from "./styles";

const ActiveBox = ({ data, changeAssetColor }) => {
  if (typeof data === "undefined") {
    return <></>;
  }

  return (
    <Wrapper>
      <ColorPicker
        value={data.color}
        changeAssetColor={changeAssetColor}
        asset={data}
      />
      <Span>{data.label}</Span>
    </Wrapper>
  );
};

export default ActiveBox;
