import React from "react";
import Highlighter from "react-highlight-words";

import { useMyContext } from "hooks";
import Utils from "utils/utils";
import {
  SearchEuropeFundInfo,
  SearchFixedIncomeInfo,
  SearchFundInfo,
  SearchStockInfo,
} from "components";

import { AssetLabel, AssetType, AssetInfoContainer, ListItem } from "./styles";

const SearchAssetItem = ({ asset, handleOnMouseDown, wordsToHighlight }) => {
  const { t } = useMyContext();
  const { removeDiacritics } = Utils();

  const {
    assetType,
    cnpj,
    isin,
    label,
    managementCompany,
    situation,
    stockExchange,
    type,
  } = asset;

  return (
    <ListItem onMouseDown={handleOnMouseDown}>
      <AssetType>{t[`assetType:${assetType}`]}</AssetType>

      <AssetLabel textsize="lg" weight="600">
        <Highlighter
          searchWords={wordsToHighlight}
          autoEscape={true}
          textToHighlight={label}
          highlightClassName={"highlighted-word"}
          sanitize={removeDiacritics}
        />
      </AssetLabel>

      <AssetInfoContainer>
        {assetType === "ACAO" && (
          <SearchStockInfo
            type={type}
            stockExchange={stockExchange}
            situation={situation}
          />
        )}

        {assetType === "EUROPA" && (
          <SearchEuropeFundInfo
            isin={isin}
            situation={situation}
            managementCompany={managementCompany}
            wordsToHighlight={wordsToHighlight}
          />
        )}

        {(assetType === "FI" || assetType === "FII") && (
          <SearchFundInfo
            cnpj={cnpj}
            situation={situation}
            managementCompany={managementCompany}
            wordsToHighlight={wordsToHighlight}
          />
        )}

        {assetType === "RENDA_FIXA" && (
          <SearchFixedIncomeInfo type={type} situation={situation} />
        )}
      </AssetInfoContainer>
    </ListItem>
  );
};

export default SearchAssetItem;
