import { HOME_TITLE } from "environment";

const enUS = {
  "period:to": "to",
  "period:calc": "calculating ...",
  "common:asset": "Asset",
  "commom:remove": "Remove",
  "common:return": "Return",
  "common:legend": "Legend",

  "chart:months": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  "chart:months:short": [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  "chart:resetZoom": "Cancel zoom",
  "chart:desktop:zoom": "Click and drag in the chart area to zoom in",
  "chart:mobile:zoom": "Pinch the chart to zoom in",

  "molecules:TitleHome:Title:title-1": HOME_TITLE || "InvestSet Comparator,",
  "molecules:TitleHome:Title:title-2": "more than investment funds",
  "molecules:ThirdHeader:benchmark": "Benchmark",
  "molecules:ThirdHeader:periodo": "Term",
  "molecules:ThirdHeader:aplicaoInicial": "Initial investment",
  "molecules:ActiveBox:cancelar": "Cancel",
  "molecules:ActiveBox:selecionar": "Select",
  "molecules:Modals:SerieModal:alert":
    "Some assets are not on the chart as they do not have historical data for the entire period.",
  "molecules:Modals:MobileFooter:close": "Close",
  "molecules:Modals:MobileFooter:shareLinkMessage": "Sharable Link:",
  "molecules:Modals:MobileFooter:shareQRTitle": "QR Code",
  "molecules:Modals:MobileFooter:shareQRCodeMessage":
    "Open your Camera app and point your phone at the QR code.",
  "molecules:Modals:MobileFooter:copy": "Copy",
  "molecules:Modals:MobileFooter:copyed": "Copied!",
  "molecules:Card:asset": "Asset",

  "organisms:SearchAssets:searchString": "Search for name, ISIN or ticker",
  "organisms:SelectedActives:title": "Selected Assets",
  "organisms:SearchAssets:cnpj": "CNPJ",
  "organisms:SearchAssets:isin": "ISIN",
  "organisms:SearchAssets:managementCompany": "Management Company",
  "organisms:SearchAssets:type": "Type",
  "organisms:SearchAssets:situation": "Situation",
  "organisms:SearchAssets:stockExchange": "Stock Exchange",
  "organisms:SearchAssets:nenhumResultadoEncontrado": "No results found",
  "organisms:SearchHeader:viewSelectedAssets": "View selected assets",
  "organisms:OverlayRow:remover": "Remove",

  "pages:HomePage:ClearActives": "Remove all",
  "pages:HomePage:AnalysisButton": "Start analysis of selected assets ",
  "pages:HomePage:AnalysisButton:mobile": "Start analysis of assets",
  "table:Header:Indicators": "Analytics - per period",
  "table:Header:ReturnHistory": "Historical Return",

  "tabs:Rentability": "Rentability",
  "tabs:Return": "Return",
  "tabs:Underwater": "Underwater",
  "tabs:Correlation": "Correlation",

  "share:shareLink": "Share Link",
  "share:QRCode": "Generate QR Code",
  "share:copyLink": "Copy Link",
  "share:copiedLink": "Copied Link!",
  "share:invalidLink": "Invalid Link!",
  "share:loadingLink": "Link is loading... please wait.",

  "month:0": "January",
  "month:1": "February",
  "month:2": "March",
  "month:3": "April",
  "month:4": "May",
  "month:5": "June",
  "month:6": "July",
  "month:7": "August",
  "month:8": "September",
  "month:9": "October",
  "month:10": "November",
  "month:11": "December",

  "shortMonth:0": "Jan",
  "shortMonth:1": "Feb",
  "shortMonth:2": "Mar",
  "shortMonth:3": "Apr",
  "shortMonth:4": "May",
  "shortMonth:5": "June",
  "shortMonth:6": "July",
  "shortMonth:7": "Aug",
  "shortMonth:8": "Sept",
  "shortMonth:9": "Oct",
  "shortMonth:10": "Nov",
  "shortMonth:11": "Dec",

  "period:NO_MES": "Month",
  "period:UM_ANO_UTIL": "1 year",
  "period:SEIS_MESES_UTEIS": "6 months",
  "period:CINCO_ANOS_UTEIS": "5 years",
  "period:OTIMO": "Optimal",

  "measure:RENTABILIDADE": "Return",
  "measure:RETORNO": "Return",
  "measure:PATRIMONIO_LIQUIDO": "AuM Euro Millions",
  "measure:SHARPE": "Sharpe Ratio",
  "measure:TAXA_DE_ADMINISTRACAO": "Ongoing Cost",
  "measure:VOLATILIDADE": "Volatility",

  "measure:RETORNO_UM_ANO": "1 year Return",
  "measure:RETORNO_YTD": "YTD Return",
  "measure:DRAW_DOWN": "Drawdown",
  "measure:INICIO_DO_1_DRAW_DOWN": "Maximum Drawdown Starting Date",
  "measure:DATA_DO_1_DRAW_DOWN": "Maximum Drawdown Date",
  "measure:PRIMEIRO_DRAW_DOWN": "Maximum Drawdown",

  "assetType:FI": "FI",
  "assetType:FII": "FII",
  "assetType:EUROPA": "", // FIXME: Produtos vai passar o label correto futuramente.
  "assetType:ACAO": "Stock",
  "assetType:RENDA_FIXA": "Fixed Income",
  "assetType:EMPRESA_ABERTA": "Public Company",
};

export default enUS;
