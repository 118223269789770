import styled, { keyframes } from "styled-components";
import { FaSpinner } from "react-icons/fa";
import Div from "components/atoms/Div";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
`;

export const Spinner = styled(FaSpinner)`
  animation: ${rotate} 2s linear infinite;
`;
