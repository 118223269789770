import styled from "styled-components";
import { Div, Img } from "components";
import { Link } from "react-router-dom";

export const LogoWrapper = styled(Div)`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.logo.padding};
  justify-content: ${({ theme }) => theme.logo.align};

  img {
    height: ${({ theme }) => theme.logo.height};
  }
`;

export const Content = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 800px;
  flex-grow: 1;

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const BottomContainer = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 920px) {
    flex-grow: 1;
  }
`;

export const AnalysisButton = styled(Link)`
  margin: 56px 0;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: white;
  padding: 16px 27px;
  align-self: center;
  font-weight: ${({ theme }) => theme.fonts.weight[2]};

  :hover {
    background-color: ${({ theme }) =>
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]};
  }

  @media (max-width: 920px) {
    font-size: 14px;
    padding: 10px;
    margin: 18px 0 18px 0;
    background-color: white;
    color: #333;

    :hover {
      background-color: white;
    }
  }
`;

export const BackgroundImages = styled(Div)``;

export const Image1 = styled(Img)`
  position: fixed;
  bottom: 131px;
  right: 0;
`;

export const Image2 = styled(Img)`
  position: fixed;
  bottom: 0;
  right: 127px;
`;

export const Image3 = styled(Img)`
  position: fixed;
  top: 230px;
  left: 0;
`;
