const ES = {
  "organisms:Forms:SignInForm:Username": "Usuario",
  "organisms:Forms:SignInForm:Password": "Contraseña",
  "organisms:Forms:SignInForm:SignIn": "Registrarse",
  "organisms:Forms:SignInForm:notSignedUp": "Todavía no estas registrado?",
  "organisms:Forms:SignInForm:createAnAccount": "Crea una cuenta",
  "organisms:Forms:SignInForm:invalid_username_or_password":
    "Usuario o contraseña invalido",

  "organisms:Forms:SignUpForm:HeaderCreate": "Crea una cuenta",
  "organisms:Forms:SignUpForm:HeaderInfo":
    "Complete su información para obtener acceso gratuito",
  "organisms:Forms:SignUpForm:users_username_unique":
    "Nombre de usuario ya existe",
  "organisms:Forms:SignUpForm:users_email_unique":
    "Correo electronico ya existe",
  "organisms:Forms:SignUpForm:SignUp": "Registrarse",
  "organisms:Forms:SignUpForm:AlreadySignUp": "¿Ya estás registrado?",
  "organisms:Forms:SignUpForm:SignIn": "Iniciar sesión",
  "organisms:Forms:SignUpForm:Name": "Nombre",
  "organisms:Forms:SignUpForm:Email": "Correo Electrónico",
  "organisms:Forms:SignUpForm:Username": "Nombre de usuario",
  "organisms:Forms:SignUpForm:Password": "Contraseña",
  "organisms:Forms:SignUpForm:ConfirmPassword": "Confirmar contraseña",
  "organisms:Forms:SignUpForm:TermsAndPrivacy":
    "Al crear una cuenta, acepta los Términos de uso y la Política de privacidad.",
};

export default ES;
