import styled from "styled-components";
import { rgba } from "polished";
import { Div, Text } from "components";

export const ListItem = styled.li`
  text-decoration: none;
  background-color: white;
  display: flex;
  padding: 0 16px;
  padding-top: 16px;
  flex-direction: column;
  border-bottom: 1px solid ${rgba("#121212", 0.2)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors[theme.background.quintenary.color][
        theme.background.quintenary.intensity
      ]};
  }
`;

export const AssetLabel = styled(Text)`
  color: #3a5267;

  .highlighted-word {
    font-weight: bolder;
    color: inherit;
    background-color: inherit;
  }

  @media (max-width: 920px) {
    color: #27618f;
  }
`;

export const AssetType = styled.small`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: rgba(102, 102, 102, 0.8);
  line-height: ${({ theme }) => theme.fonts.lineHeight[1]};
  font-weight: ${({ theme }) => theme.fonts.weight[0]};
`;

export const AssetInfoContainer = styled(Div)`
  display: flex;
  margin-bottom: 20px;

  span:first-child {
    border: none;
    margin: 0;
    padding: 0;
  }

  span {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid rgba(102, 102, 102, 0.8);

    /* Não aplicar estilos nas palavras destacadas */
    span {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 920px) {
    flex-direction: column;

    span {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
`;
