import styled from "styled-components";
import { Div } from "components";

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 696px;
  height: 359.88px;
  background-color: white;
  padding: 0;

  @media (max-width: 920px) {
    padding: 0;
    width: 100%;
  }
`;

export const Body = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: row;
  word-wrap: break-word;
  height: 288px;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};

  @media (max-width: 920px) {
    width: 90%;
  }
`;

export const LeftColumn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 408px;
  height: 288px;
  padding: 0;

  @media (max-width: 920px) {
    padding: 0;
    width: 50%;
  }
`;

export const QRTitle = styled(Div)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: #333333;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;

  width: 100%;
  padding-left: 40px;
  padding-bottom: 12px;
  display: flex;
  align-items: left;

  @media (max-width: 920px) {
    padding: 0;
  }
`;

export const QRText = styled(Div)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: #333333;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;

  width: 100%;
  padding-left: 40px;

  display: flex;
  align-items: center;

  @media (max-width: 920px) {
    padding: 0;
  }
`;

export const RightColumn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 288px;
  background-color: #f2f1f6;
  margin: 0;
  padding: 0;
  @media (max-width: 920px) {
    padding: 0;
    width: 50%;
  }
`;

export const BottomBar = styled(Div)`
  display: flex;
  justify-content: flex-end;
  align-items: right;
  padding-right: 40px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
  width: 100%;
  height: 70.88px;
`;

export const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #333333;
`;
