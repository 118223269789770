import styled from "styled-components";
import { Div } from "components";

export const Wrapper = styled(Div)`
  position: fixed;
  background-color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]
      : theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]};
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000002;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Message = styled(Div)`
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;
