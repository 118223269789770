import React, { useEffect, useState } from "react";
import * as Highcharts from "highcharts";

import defaultTheme from "themes/Themes/clients/defaultTheme";
import { useMyContext } from "hooks";
import { usePeriodRange } from "../../../state";
import Utils from "utils/utils";
import { Wrapper } from "./styles";

const HigchartsBase = ({ assets, selectedTab }) => {
  const { formatDate, formatCurrency, formatPercent, formatPeriod } = Utils();
  const { themeType, t } = useMyContext();
  const [periodRange] = usePeriodRange();
  const [title, setTitle] = useState("");
  const theme = defaultTheme[themeType];

  const { innerWidth } = window;

  useEffect(() => {
    setChartTitle(periodRange, selectedTab);
  }, [periodRange, selectedTab]);

  const setChartTitle = (periodRange, selectedTab) => {
    const { initialDate, finalDate } = periodRange;

    setTitle(
      `${getTabTitle(selectedTab)} - ${
        initialDate && finalDate ? formatPeriod(finalDate, initialDate) : ""
      }`
    );
  };

  const getTabTitle = (tab) => {
    switch (tab) {
      case 0:
        return t["tabs:Rentability"];
      case 1:
        return t["tabs:Return"];
      case 2:
        return t["tabs:Underwater"];
      default:
        return null;
    }
  };

  const getData = (asset, tab) => {
    switch (tab) {
      case 0:
        return asset.rentabilitySeries;
      case 1:
        return asset.returnSeries;
      case 2:
        return asset.drawdownSeries;
      default:
        return null;
    }
  };

  const toolTipValue = (value) => {
    switch (selectedTab) {
      case 0:
        return formatCurrency(value);
      case 1:
      case 2:
        return formatPercent(value);
      default:
        return value;
    }
  };

  const getType = () => {
    switch (selectedTab) {
      case 0:
        return "line";
      case 1:
        return "line";
      case 2:
        return "area";
      default:
        return null;
    }
  };

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        months: t["chart:months"],
        shortMonths: t["chart:months:short"],
        resetZoom: t["chart:resetZoom"],
      },
    });
    Highcharts.chart("line", {
      credits: {
        enabled: false,
      },
      chart: {
        style: {
          fontFamily: theme.fonts.primary,
        },
        zoomType: "x",
        type: getType(),
        backgroundColor:
          innerWidth <= 920
            ? theme.colors[theme.background.quaternary.color][
                [theme.background.quaternary.intensity]
              ]
            : "white",
        events: {
          click: function (e) {
            //TODO: tela de detalhes
          },
        },
      },
      title: {
        text: innerWidth > 920 ? title : "",
        align: "left",
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? t["chart:desktop:zoom"]
            : t["chart:mobile:zoom"],
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b. %y",
        },
        gridLineWidth: 0,
        labels: {
          style: {
            color:
              innerWidth <= 920
                ? "white"
                : theme.colors[theme.textColors.primary.color][
                    theme.textColors.primary.intensity
                  ],
          },
        },
      },
      yAxis: {
        gridLineColor:
          innerWidth <= 920
            ? "rgba(255, 255, 255, 0.2)"
            : "rgba(51, 51, 51, 0.2)",
        title: {
          text: false,
        },
        labels: {
          formatter: function () {
            switch (selectedTab) {
              case 0:
                return formatCurrency(this.value);
              case 1:
                return `${this.value}%`;
              case 2:
                return `${this.value}%`;
              default:
                return null;
            }
          },
          style: {
            color:
              innerWidth <= 920
                ? "white"
                : theme.colors[theme.textColors.primary.color][
                    theme.textColors.primary.intensity
                  ],
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return `${s}<div style="display: flex; width: 100%; justify-content: space-between; margin-top: 16px;"><div style="display: flex; align-items: center;"><div style="display:flex; background-color: ${point.series.color}; width: 8px; height: 8px; margin-right: 4px;"></div> <span style="width: 165px; overflow: hidden; text-overflow: ellipsis;">${point.series.name}</span></div> <span style="display: flex; algin-self: center; font-weight: bold; margin-left: 20px;">${toolTipValue(point.y)}</span> </div>`;
          }, '<b style="color: #27618F;">' + formatDate(this.x) + "</b>");
        },
        shared: true,
        crosshairs: true,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
          animation: false,
        },
        area: {
          marker: {
            radius: 2,
          },
          animation: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: 0,
        },
      },
      series: assets.map((asset) => ({
        data: getData(asset, selectedTab),
        name: asset.label,
        lineWidth: 3,
        color: asset.color,
        fillOpacity: 0.2,
      })),
    });
  }, [assets, selectedTab, title]);

  return (
    <Wrapper
      id="line"
      style={{
        height: "565px",
        backgroundColor: innerWidth <= 920 ? "none" : "white",
      }}
    />
  );
};

export default HigchartsBase;
