import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import Providers from "./Providers";
import Modal from "react-modal";

Modal.setAppElement("#app");

localStorage.setItem("currency", "EUR");

const renderApp = () => {
  return (
    <Providers>
      <App />
    </Providers>
  );
};

render(renderApp(), document.getElementById("app"));
