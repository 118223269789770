import styled from "styled-components";
import { Div } from "components";

export const Chart = styled(Div)`
  padding: 32px;
  display: flex;
  justify-content: start;
  margin-top: 2px;
  overflow-x: auto;

  @media (max-width: 920px) {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;

export const AssetsList = styled.ul`
  list-style-type: none;
  padding-left: 10%;

  @media (max-width: 920px) {
    padding: 5%;
    background-color: ${({ theme }) =>
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]};
  }
`;

export const Spacer = styled.li`
  padding: 28px 0 16px 12px;
  color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  @media (max-width: 920px) {
    padding: 12px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 12px;
    span {
      color: ${({ theme }) =>
        theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
    }
  }
`;

export const AssetsItem = styled.li`
  min-height: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]} !important;
  padding: 14px 0 14px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 920px) {
    padding: 12px 0px;
  }
`;

export const Content = styled(Div)`
  display: flex;
  margin-bottom: 35px;

  @media (max-width: 920px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 0px;
  }
`;

export const Legend = styled(Div)`
  width: 384px;
  padding-top: 32px;

  @media (max-width: 920px) {
    padding-bottom: 35px;
    width: auto;
  }
`;

export const RightContent = styled(Div)``;

export const Title = styled(Div)`
  font-family: Open Sans;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-left: 10%;
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]} !important;

  @media (max-width: 920px) {
    display: none;
  }
`;
