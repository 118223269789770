import React from "react";

import { StyledFooterText, StyledImg } from "./styles";
import logoQuantum from "assets/logoQuantum.svg";

const FooterGraphicPage = () => (
  <>
    <StyledFooterText textsize="sm" style={{ marginRight: "8px" }}>
      Powered by
    </StyledFooterText>
    <StyledImg src={logoQuantum} />
  </>
);

export default FooterGraphicPage;
