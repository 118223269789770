import axios from "axios";
import { API_SUBSCRIPTION_KEY, API_URL } from "environment";
import { stringify } from "query-string";

const api = axios.create({
  baseURL: API_URL,
  headers: { "x-subscriptionkey": API_SUBSCRIPTION_KEY },
});

export const createQuantumApi = () => {
  return {
    ...api,
    fetchPeriods: () =>
      api
        .get("period")
        .then((res) => res.data)
        .catch(console.log),

    fetchPeriodRange: (period, assets) =>
      api
        .get(
          `period/${period}${
            period === "OTIMO"
              ? "?" +
                stringify(
                  {
                    assetType: assets.map((asset) => asset.assetType),
                    identifier: assets.map((asset) => asset.identifier),
                  },
                  { arrayFormat: "separator", arrayFormatSeparator: "|" }
                )
              : ""
          }`
        )
        .then((res) => res.data)
        .catch((err) => {
          if (err.response.status === 404) {
            return {
              initialDate: null,
              finalDate: null,
              assetsWithNoSeriesIntersection: [],
            };
          }
          throw err;
        }),

    fetchOptimumPeriod: (assets) =>
      api.get(
        `period/optimum?${stringify(
          {
            assetType: assets.map((asset) => asset.assetType),
            identifier: assets.map((asset) => asset.identifier),
          },
          { arrayFormat: "separator", arrayFormatSeparator: "|" }
        )}`
      ),

    fetchRentabilitySeries: (
      assetType,
      assetIdentifier,
      periodRange,
      initialApplication
    ) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/return/series?${stringify(
            {
              initialApplication: initialApplication,
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchReturnSeries: (assetType, assetIdentifier, periodRange) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/return/cumulative/series?${stringify(
            {
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchDrawdownSeries: (assetType, assetIdentifier, periodRange) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/drawdown/series?${stringify(
            {
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            }
          )}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchReturnHistory: (assetType, assetIndentifier) =>
      api
        .get(`calculation/${assetType}/${assetIndentifier}/return/history`)
        .then((res) => res)
        .catch(console.log),

    fetchIndicator: (
      assetType,
      assetIdentifier,
      measure,
      periodRange,
      initialApplication = false
    ) =>
      api
        .get(
          `calculation/${assetType}/${assetIdentifier}/${measure}?${stringify({
            initialApplication: initialApplication ? initialApplication : "",
            initialDate: periodRange.initialDate,
            finalDate: periodRange.finalDate,
          })}`
        )
        .then((res) => res)
        .catch(console.log),

    fetchBenchmarksAssets: () =>
      api
        .get("asset/indices")
        .then((res) => res)
        .catch(console.log),

    fetchCorrelationData: (periodRange, assets) =>
      api
        .get(
          `calculation/correlation?${stringify(
            {
              assetType: assets.map((asset) => asset.assetType),
              identifier: assets.map((asset) => asset.identifier),
              initialDate: periodRange.initialDate,
              finalDate: periodRange.finalDate,
            },
            { arrayFormat: "separator", arrayFormatSeparator: "|" }
          )}`
        )
        .then((res) => res.data)
        .catch(console.log),

    fetchAssetsNames: (assetType, assetIdentifier) =>
      api
        .get(
          `calculation/${String(assetType)}/${String(
            assetIdentifier
          )}/NOME/?${stringify({
            initialDate: "13-12-2020",
            finalDate: "13-12-2020",
          })}`
        )
        .then((res) => String(res.data.value))
        .catch(console.log),
  };
};
