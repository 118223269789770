export const initialState = {
  assets: [],
  assetsWithoutSeries: [],
  selectedPeriod: "UM_ANO_UTIL",
  initialApplication: 100,
  selectedTab: 0,
  selectedBenchmarksIndexes: [],
  correlationData: {},
  benchmarkAssets: [],
  isFetchingAssetsSeries: false,
  periodRange: {
    initialDate: null,
    finalDate: null,
  },
};

export * from "./reducer";
export * from "./selectors";
