import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { Div } from "components";

export const StyledAgGrid = styled(AgGridReact)`
  .ag-center-cols-clipper {
    min-height: unset !important;
  }
  .ag-cell {
    color: ${({ theme }) =>
      theme.colors[theme.textColors.primary.color][
        theme.textColors.primary.intensity
      ]};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 14px;
    line-height: 16px;
  }
  .ag-header-cell {
    background-color: white;
  }
  .ag-pinned-right-header,
  .ag-horizontal-right-spacer {
    width: 0 !important;
    min-width: 0 !important;
    border: none;
  }
  .example-full-width-pinned-row {
    background-color: #2aabd2;
    /* border: 2px solid darkblue; */
    /* we want the border to be kept within the row height */
    box-sizing: border-box;
    /* get the row to fill the available height */
    height: 100%;
    /* grid sets white-space to one line, need to reset for wrapping the text */
    white-space: normal;
  }
  .ag-cell-first-right-pinned {
    width: 100% !important;
  }
  .ag-pinned-right-cols-container {
    min-width: 100% !important;
    width: 100% !important;
  }

  .ag-pinned-right-cols-container * {
    pointer-events: initial;
    background-color: #e5e5e5;
  }
  .ag-pinned-right-cols-container .ag-cell {
    border: none !important;
  }
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
    width: 0 !important;
    padding: 0 !important;
  }
  .ag-pinned-right-cols-container {
    position: absolute !important;
    right: 0;
    pointer-events: none;
  }
  .ag-react-container {
    width: 100%;
  }
  .ag-root-wrapper {
    border: none;
  }

  .last-header {
    border-right: none !important;
  }

  .ag-header {
    .ag-pinned-left-header {
      span {
        align-self: flex-start;
      }
    }
  }

  .ag-header-row-column {
    .ag-header-cell {
      border-right: 1px solid rgba(51, 51, 51, 0.4);
      padding: 0;

      .ag-header-cell-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .last-column-header {
    border-right: none !important;
  }

  .ag-react-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export const ColumnHeader = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  strong {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fonts.size.md};
  }
  span {
    font-weight: 400;
    font-size: 12px;
    margin-top: 4px;
  }

  @media (max-width: 920px) {
    strong {
      font-weight: 600;
      font-size: ${({ theme }) => theme.fonts.size.sm};
    }
  }
`;

export const AssetColumnHeader = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
`;
