export const BENCHMARKS_COLOR_PALETTE = [
  "#6a9bac",
  "#89d3b1",
  "#ff9b94",
  "#ffd6b2",
];

export const ASSETS_COLOR_PALETTE = [
  "#4f94ef",
  "#a2a2a2",
  "#f0c484",
  "#96d7fa",
  "#6dba82",
  "#c3c6d5",
  "#f5a336",
  "#e78d7a",
  "#fef267",
  "#c6c6c6",
  "#cacb4c",
  "#6a9bac",
  "#ef8db9",
  "#e3ec6f",
  "#b1b1f6",
  "#ebd4a0",
  "#9394c1",
  "#f9bcaf",
  "#93e1a3",
];

export const RED_CORRELATION_GRAPH = "#E35D81";
export const ORANGE_CORRELATION_GRAPH = "#EE8C68";
export const YELLOW_CORRELATION_GRAPH = "#FCD401";
export const GREEN_CORRELATION_GRAPH = "#4DDC8F";
export const BLUE_CORRELATION_GRAPH = "#4EAFE0";
export const BLUE_QUANTUM = "#254057";
export const INITIAL_BENCHMARK_INDEX = 0;

export const URL_FIELDS_SIZE = 5;
export const AMOUNT_MIN = 0;
export const AMOUNT_MAX = 1000000;
export const VALID_TABS = [
  "RENTABILIDADE",
  "RETORNO",
  "UNDERWATER",
  "CORRELATION",
];
export const VALID_BENCHMARKS = [
  "DAX",
  "S&P_500_TOTAL_RETURN",
  "EURO_STOXX_50",
  "VIX",
  "MSCI_WORLD_ESG_LEADERS",
];
export const VALID_PERIODS = [
  "OTIMO",
  "NO_MES",
  "UM_ANO_UTIL",
  "SEIS_MESES_UTEIS",
  "CINCO_ANOS_UTEIS",
];

export const PERIOD_OPTIMAL = "OTIMO";

export const QR_CODE_SQUARE_SIZE = 208;

export const BENCHMARKS_ASSETS_IDS = [1002, 1060, 1003, 104, 1399];

export const WHATSAPP_API_URL = "https://api.whatsapp.com/send?text=";
