import styled from "styled-components";
import CurrencyInput from "react-currency-input";
import { Text, Div } from "components";
import Item from "components/molecules/NavItem";

export const Wrapper = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  @media (max-width: 920px) {
    flex-wrap: wrap;
  }
`;

export const NavBox = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (max-width: 920px) {
    padding: 0px 16px;
    margin-bottom: 0px;
    & + & {
      margin-top: 16px;
    }
  }

  @media (min-width: 630px) {
    margin-top: 16px;
  }
`;

export const NavItem = styled(Item)`
  border: ${({ hasBorder, theme }) =>
    !!hasBorder &&
    `1px solid ${
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]
    }`};
  padding: ${({ hasBorder }) => !!hasBorder && "10px 14px"};
  border-radius: ${({ hasBorder }) => !!hasBorder && "4px"};
  font-size: ${({ theme }) => theme.fonts.size.md};

  & + & {
    margin-left: ${({ hasBorder }) => (!!hasBorder ? "12px" : "0px")};
  }

  @media (max-width: 920px) {
    border: ${({ hasBorder }) => !!hasBorder && `1px solid white`};
    padding: 10px;
  }
`;

export const NavLeft = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const NavMiddle = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
  padding: 12px 12px 12px 0px;
  text-align: end;
  border: none;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.quaternary.color][
      theme.background.quaternary.intensity
    ]};

  @media (min-width: 920px) {
    width: 130px;
  }

  @media (min-width: 1615px) {
    width: 181px;
  }
`;
