import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
import { useMyContext } from "hooks";
import { SearchAssetItem, Text } from "components";

import {
  Wrapper,
  SearchList,
  StyledTextField,
  SearchListWrapper,
} from "./styles";

const REGEX_TO_HIGHLIGHT = /\s*(?: |$)\s*/;

const SearchAssets = ({
  searchStringRef,
  style,
  initialApplication,
  period,
  addAsset,
}) => {
  const { t } = useMyContext();
  const [isOpenSearchList, setIsOpenSearchList] = useState(false);
  const [searchedItems, setSearchedItems] = useState([]);
  const [wordsToHighlight, setWordsToHighlight] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = async (searchValue) => {
    try {
      const result = await api.get(`asset?searchString=${searchValue}`);
      return result.data;
    } catch (err) {
      console.log(err);
    }
  };

  const processAssets = (searchedAssets) => {
    const keys = Object.keys(searchedAssets);
    let searchedAssetsArray = [];
    keys.forEach((value) => {
      searchedAssets[value].forEach((asset) => {
        searchedAssetsArray.push(asset);
      });
    });
    return searchedAssetsArray;
  };

  const doSearch = async (searchValue) => {
    setIsLoading(true);
    const searchedAssets = await fetchAssets(searchValue);
    const searchedAssetsArray = processAssets(searchedAssets);
    setIsLoading(false);

    const wordsToHighlightArr = searchValue
      ? searchValue.trim().split(REGEX_TO_HIGHLIGHT)
      : [];
    setWordsToHighlight(wordsToHighlightArr);

    setSearchedItems(searchedAssetsArray);
    setIsOpenSearchList(true);
  };

  const handleChange = async () => {
    const searchValue = searchStringRef.current.value;

    if (searchValue.length >= 3) {
      doSearch(searchValue);
    } else {
      setSearchedItems([]);
      setIsOpenSearchList(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleCancel = () => {
    setIsFocused(false);
    setIsOpenSearchList(false);
    setSearchedItems([]);
    searchStringRef.current.value = "";
  };

  return (
    <Wrapper style={style}>
      <StyledTextField
        id="search-active"
        type="text"
        reference={searchStringRef}
        placeholder={t["organisms:SearchAssets:searchString"]}
        leftIconName="search"
        fullWidth
        onChange={handleChange}
        onFocus={handleFocus}
        isFocused={isFocused}
        hasLoading={isLoading}
        onCancel={searchedItems.length > 0 && handleCancel}
      />
      <SearchListWrapper>
        {isOpenSearchList && (
          <SearchList>
            {searchedItems.length > 0 ? (
              searchedItems.map((item) => (
                <SearchAssetItem
                  key={item.identifier + item.assetType}
                  asset={item}
                  handleOnMouseDown={() => {
                    addAsset(item, period, initialApplication);
                    handleCancel();
                  }}
                  wordsToHighlight={wordsToHighlight}
                />
              ))
            ) : (
              <Text
                style={{ marginTop: "10px", padding: "0 16px" }}
                textsize="lg"
                weight="600"
                color="blue"
              >
                {t["organisms:SearchAssets:nenhumResultadoEncontrado"]}
              </Text>
            )}
          </SearchList>
        )}
      </SearchListWrapper>
    </Wrapper>
  );
};

SearchAssets.propTypes = {
  searchStringRef: PropTypes.any.isRequired,
  addActive: PropTypes.func,
};

export default SearchAssets;
