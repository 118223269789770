import styled from "styled-components";
import { Div } from "components";

export const Wrapper = styled(Div)`
  padding: 32px;

  @media (max-width: 920px) {
    padding: 0;

    .highcharts-subtitle {
      color: ${({ theme }) =>
        theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]} !important;
      fill: ${({ theme }) =>
        theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]} !important;
    }
  }

  .highcharts-title {
    font-weight: 600;
  }
`;
