import React, { useState } from "react";
import { useMyContext } from "hooks";
import { Wrapper, NavItem, NavLeft, StyledImg } from "./styles";
import {
  useSelectAssets,
  useSelectBenchmarksIndexes,
  useSelectInicialApplication,
  useSelectPeriod,
  useSelectSharedLink,
  useSelectTab,
} from "state";
import { generateSharedLink, getCurrentUrlBase } from "utils/urlParser";
import SharedLinkModal from "../Modals/SharedLinkModal";
import copyLinkIcon from "../../../assets/copyLinkIcon.svg";
import QRCodeModal from "../Modals/QRCodeModal";
import shareQRCodeIcon from "../../../assets/shareQRCode.svg";
import CopyLinkModal from "../Modals/CopyLinkModal";

const SecondHeader = ({ selectedTab, setSelectedTab }) => {
  const { t } = useMyContext();
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false);

  const { innerWidth } = window;

  const handleNavItemClick = (e) => {
    const index = e.currentTarget.dataset.index;
    setSelectedTab(Number(index));
  };

  const [sharedLink, setSharedLink] = useSelectSharedLink();
  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();
  const [selectedTabState, setSelectedTabState] = useSelectTab();
  const [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes] =
    useSelectBenchmarksIndexes();
  const [selectedPeriod, setSelectedPeriod] = useSelectPeriod();

  const [assets, setAssets] = useSelectAssets();

  const handleShareClick = async () => {
    const sharedLinkURL = generateSharedLink(
      initialApplication,
      selectedTabState,
      selectedBenchmarksIndexes,
      selectedPeriod,
      assets
    );
    if (sharedLinkURL !== null) {
      setSharedLink(sharedLinkURL);
      setCopyModalOpen(true);
      // setLinkModalOpen(true);
    }
  };

  const handleQRCodeClick = async () => {
    const sharedLinkURL = generateSharedLink(
      initialApplication,
      selectedTabState,
      selectedBenchmarksIndexes,
      selectedPeriod,
      assets
    );
    if (sharedLinkURL !== null) {
      setSharedLink(sharedLinkURL);
      setQRCodeModalOpen(true);
    }
  };

  const clientURL = getCurrentUrlBase();

  return (
    <Wrapper>
      <CopyLinkModal
        isOpen={copyModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setCopyModalOpen(false)}
      />
      <SharedLinkModal
        isOpen={linkModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setLinkModalOpen(false)}
      />
      <QRCodeModal
        isOpen={qrCodeModalOpen}
        link={clientURL + sharedLink}
        onClose={() => setQRCodeModalOpen(false)}
      />
      <NavLeft>
        <NavItem
          index={0}
          isSelected={selectedTab === 0}
          onClick={handleNavItemClick}
        >
          {t["tabs:Rentability"]}
        </NavItem>
        <NavItem
          index={1}
          isSelected={selectedTab === 1}
          onClick={handleNavItemClick}
        >
          {t["tabs:Return"]}
        </NavItem>
        <NavItem
          index={2}
          isSelected={selectedTab === 2}
          onClick={handleNavItemClick}
        >
          {t["tabs:Underwater"]}
        </NavItem>
        <NavItem
          index={3}
          isSelected={selectedTab === 3}
          onClick={handleNavItemClick}
        >
          {t["tabs:Correlation"]}
        </NavItem>
      </NavLeft>

      <NavLeft style={{ border: "none" }}>
        {innerWidth > 920 ? (
          <>
            {" "}
            <NavItem index={4} onClick={handleShareClick}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <StyledImg src={copyLinkIcon} height="18px" width="18px" />
                {t["share:copyLink"]}
              </div>
            </NavItem>
            <NavItem index={5} onClick={handleQRCodeClick}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <StyledImg src={shareQRCodeIcon} height="18px" width="18px" />
                {t["share:QRCode"]}
              </div>
            </NavItem>
          </>
        ) : null}
      </NavLeft>
    </Wrapper>
  );
};

export default SecondHeader;
