import { useContext } from "react";
import { ThemeContext } from "themes";
import { useHistory, useParams } from "react-router-dom";
import { LanguageContext } from "../../i18n";

const useMyContext = () => {
  const history = useHistory();
  const params = useParams();
  const { changeLanguage, t } = useContext(LanguageContext);
  const { toggleTheme, theme, themeType, setThemeType } =
    useContext(ThemeContext);

  return {
    toggleTheme,
    theme,
    t,
    changeLanguage,
    history,
    params,
    themeType,
    setThemeType,
  };
};

export default useMyContext;
