import styled from "styled-components";
import { rgba } from "polished";
import { Li } from "components";

export const Wrapper = styled(Li)`
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 10px 14px;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors[theme.background.tertiary.color][
          theme.background.tertiary.intensity
        ]
      : "white"};
  color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : rgba(
          theme.colors[theme.textColors.primary.color][
            theme.textColors.primary.intensity
          ],
          0.8
        )};
  cursor: pointer;
  min-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => {
    return theme.fonts.size.lg;
  }};
  font-weight: ${({ theme }) => {
    return theme.fonts.weight[0];
  }};
  line-height: ${({ theme }) => {
    return theme.fonts.lineHeight[2];
  }};

  @media (max-width: 920px) {
    background-color: ${({ isSelected }) =>
      isSelected ? "white" : "transparent"};
    color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.colors[theme.textColors.secondary.color][
            theme.textColors.secondary.intensity
          ]
        : rgba(
            theme.colors[theme.textColors.primary.color][
              theme.textColors.primary.intensity
            ],
            0.8
          )};
    min-width: fit-content;
  }
`;
