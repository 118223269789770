import React, { useEffect, useRef, useState } from "react";

import { Img, SearchAssets, SelectedAssets } from "components";
import { Header, ImageWrapper, ViewSelectedAssets, Wrapper } from "./styles";
import { useAssets, useMyContext } from "hooks";

const SearchHeader = ({ period, initialApplication }) => {
  const searchStringRef = useRef();
  const { history, theme, t } = useMyContext();

  const [assets, setAssets] = useState([]);

  const {
    assets: allAssets,
    addAsset,
    removeAsset,
    removeAllAssets,
    removeAllAssetsExceptBenchmarks,
  } = useAssets();

  const imageClick = () => {
    removeAllAssetsExceptBenchmarks();
    // history.push("/");
  };

  const handleViewSelectedAssets = () => {
    history.push("/");
  };

  useEffect(() => {
    const assetsWithoutBenchmarks = allAssets.filter(
      ({ assetType }) => assetType !== "INDICE"
    );
    setAssets(assetsWithoutBenchmarks);
  }, [allAssets]);

  return (
    <Wrapper>
      <Header>
        {theme.logo.show && (
          <ImageWrapper>
            <Img onClick={imageClick} src={theme.logo.img} />
          </ImageWrapper>
        )}
        <SearchAssets
          period={period}
          addAsset={addAsset}
          initialApplication={initialApplication}
          searchStringRef={searchStringRef}
          style={{ marginLeft: theme.searchAssets.marginLeft }}
        />
        {theme.selectedAssets.viewButton && (
          <ViewSelectedAssets onClick={handleViewSelectedAssets}>
            {t["organisms:SearchHeader:viewSelectedAssets"]}
          </ViewSelectedAssets>
        )}
      </Header>
      {theme.selectedAssets.show && (
        <SelectedAssets
          assets={assets}
          clearAssets={removeAllAssetsExceptBenchmarks}
          removeAsset={removeAsset}
        />
      )}
    </Wrapper>
  );
};

export default SearchHeader;
