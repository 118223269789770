import React from "react";
import { AssetColumnHeader as ACH, ColumnHeader as CH } from "./styles";

export const CustomHeader = ({ period, customDisplayName, displayName }) => {
  return (
    <CH>
      <strong>{customDisplayName || displayName}</strong>
      {period && <span>{period}</span>}
    </CH>
  );
};

export const AssetColumHeader = ({ displayName }) => {
  return (
    <ACH>
      <strong>{displayName}</strong>
    </ACH>
  );
};

export const getRowNodeId = (data) => {
  return data.id;
};

export const lastRowBorder = (params) => {
  if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
    return { borderBottom: "none" };
  } else {
    return { borderBottom: "1px solid rgba(51, 51, 51, 0.4)" };
  }
};
