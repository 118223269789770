import styled from "styled-components";
import { Div, Button, Title } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
`;

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
  background-color: ${({ theme, bgColor }) =>
    bgColor
      ? bgColor
      : theme.colors[theme.background.tertiary.color][
          theme.background.tertiary.intensity
        ]};
  border: none;
  border-radius: 2px;

  :hover {
    background-color: ${({ theme, bgColor }) =>
      bgColor
        ? bgColor
        : theme.colors[theme.background.tertiary.color][
            theme.background.tertiary.intensity
          ]};
  }

  & + button {
    margin-left: 10px;
  }

  @media (max-width: 920px) {
    color: ${({ theme }) =>
      theme.colors[theme.textColors.primary.color][
        theme.textColors.primary.intensity
      ]};
  }
`;

export const ButtonsContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 12px;
`;
