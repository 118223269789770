import styled from "styled-components";
import { Div, Img } from "components";

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  background-color: white;

  @media (max-width: 920px) {
    padding: 0;
    width: 100%;
  }
`;

export const TopBar = styled(Div)`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  width: 600px;

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const Body = styled(Div)`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 80%;
  padding-top: 16px;
  padding-bottom: 8px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};

  @media (max-width: 920px) {
    width: 90%;
  }
`;

export const Link = styled(Div)`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 80%;
  padding: 16px;
  border: 1px solid black;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;

  @media (max-width: 920px) {
    width: 90%;
  }
`;

export const Copy = styled.button`
  border: none;
  margin: 20px;
  padding: 10px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background: transparent;
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const BottomBar = styled(Div)`
  display: flex;
  padding: 16px 0;
  justify-content: center;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
`;

export const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const StyledImg = styled(Img)`
  margin-right: 4px;
`;
