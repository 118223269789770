import React, { useState, useEffect } from "react";

import { SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";
import "swiper/css";
import "swiper/css/pagination";

import { Card } from "components";

import { SwiperContainer } from "./styles";

SwiperCore.use([Pagination, Autoplay]);

const SwiperPagination = ({ assetsData, type }) => {
  const getColums = () => {
    if (assetsData.length > 0) {
      switch (type) {
        case "indicatorsRentability":
          if (assetsData[0]?.indicatorsRentabilityData)
            return Object.keys(assetsData[0]?.indicatorsRentabilityData);
          break;
        case "indicatorsReturn":
          if (assetsData[0]?.indicatorsReturnData)
            return Object.keys(assetsData[0]?.indicatorsReturnData);
          break;
        case "indicatorsDrawdown":
          if (assetsData[0]?.indicatorsDrawdownData)
            return Object.keys(assetsData[0]?.indicatorsDrawdownData);
          break;
        case "history":
          if (assetsData[0]?.returnHistoryData)
            return Object.keys(assetsData[0]?.returnHistoryData);
          break;
        default:
          return [];
      }
    }

    return [];
  };

  const pagination = {
    clickable: true,
  };

  return (
    <>
      <SwiperContainer spaceBetween={0} pagination={pagination}>
        {getColums().length > 0 &&
          getColums().map((key) => (
            <SwiperSlide key={key}>
              <Card title={key} assetsData={assetsData} type={type} />
            </SwiperSlide>
          ))}
      </SwiperContainer>
    </>
  );
};

export default SwiperPagination;
