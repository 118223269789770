import styled from "styled-components";
import { Text } from "components";
import { Img } from "components";

export const StyledFooterText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};

  @media (max-width: 920px) {
    color: ${({ theme }) =>
      theme.colors[theme.textColors.primary.color][
        theme.textColors.primary.intensity
      ]};
    font-size: 9px;
  }
`;

export const StyledImg = styled(Img)`
  @media (max-width: 920px) {
    width: 77px;
  }
`;
