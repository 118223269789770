import React, { useState, useEffect } from "react";
import { createQuantumApi } from "../../../api";
import { useMyContext, useAssets } from "hooks";
import {
  Wrapper,
  NavBox,
  NavMiddle,
  NavLeft,
  NavItem,
  StyledText,
  StyledCurrencyInput,
} from "./styles";
import { Period, InitialApplication } from "components";
import { useSelectInicialApplication } from "../../../state";
import Utils from "../../../utils/utils";

const api = createQuantumApi();

const ThirdHeader = ({ setSelectedPeriod, selectedPeriod, selectedTab }) => {
  const {
    benchmarkAssets,
    selectedBenchmarksIndexes,
    setSelectedBenchmarksIndexes,
    addAsset,
    removeAsset,
  } = useAssets();
  const [periods, setPeriods] = useState([]);

  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();

  const [value, setValue] = useState(initialApplication);
  // const [value, setValue] = useSelectInicialApplication();

  const MIN_APPLICATION = 1;
  const MAX_APPLICATION = 1000000000;
  const { t } = useMyContext();
  const { currencyFormatOptions } = Utils();
  const { innerWidth } = window;

  const fetchPeriods = async () => {
    const periods = await api.fetchPeriods();
    setPeriods(periods);
  };

  const handleBenchmarkClick = async (e) => {
    const index = Number(e.currentTarget.dataset.index);

    if (
      selectedBenchmarksIndexes.includes(index) &&
      selectedBenchmarksIndexes.length > 1
    ) {
      setSelectedBenchmarksIndexes(
        selectedBenchmarksIndexes.filter((x) => x !== index)
      );
    }

    if (
      selectedBenchmarksIndexes.length === 1 &&
      selectedBenchmarksIndexes.includes(index)
    )
      return;

    if (selectedBenchmarksIndexes.includes(index)) {
      await removeAsset(
        benchmarkAssets[index].identifier,
        benchmarkAssets[index].assetType
      );
    } else {
      await addAsset(
        benchmarkAssets[index],
        selectedPeriod,
        initialApplication
      );
    }
  };

  const handlePeriodClick = (e) => {
    const index = e.currentTarget.dataset.index;
    setSelectedPeriod(periods[index]);
  };

  const handleBlur = () => {
    if (initialApplication !== value) {
      if (value < MIN_APPLICATION) {
        setValue(MIN_APPLICATION);
        setInitialApplication(MIN_APPLICATION);
      } else if (value > MAX_APPLICATION) {
        setValue(MAX_APPLICATION);
        setInitialApplication(MAX_APPLICATION);
      } else {
        setInitialApplication(value);
      }
    }
  };

  const handleChange = (event, _, floatvalue) => {
    event.preventDefault();
    if (floatvalue > MAX_APPLICATION) {
      setValue(MAX_APPLICATION);
    } else {
      setValue(floatvalue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.code === "Enter") {
      handleBlur();
      event.target.blur();
    }
  };

  const benchMarkIsSelected = (index) => {
    return (
      selectedBenchmarksIndexes.length !== 0 &&
      selectedBenchmarksIndexes.includes(index)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchPeriods();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (value !== initialApplication) {
      setValue(initialApplication);
    }
  }, [initialApplication]);

  return (
    <Wrapper>
      {innerWidth > 920 ? (
        <>
          {selectedTab === 0 && (
            <NavBox style={{ width: innerWidth > 1615 ? "411px" : "135px" }}>
              <StyledText bold>
                {t["molecules:ThirdHeader:aplicaoInicial"]}
              </StyledText>
              <NavMiddle style={{ border: "none" }}>
                <StyledCurrencyInput
                  value={value}
                  decimalSeparator={currencyFormatOptions.decimalSeparator}
                  thousandSeparator={currencyFormatOptions.thousandSeparator}
                  prefix={currencyFormatOptions.currencySymbol}
                  allowNegative={false}
                  onKeyPress={handleKeyPress}
                  onBlur={handleBlur}
                  maxLength={17}
                  onChangeEvent={handleChange}
                />
              </NavMiddle>
            </NavBox>
          )}

          <NavBox>
            <StyledText bold>{t["molecules:ThirdHeader:benchmark"]}</StyledText>
            <NavLeft style={{ border: "none" }}>
              {benchmarkAssets &&
                benchmarkAssets.map((benchMark, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={benchMarkIsSelected(index)}
                    onClick={handleBenchmarkClick}
                  >
                    {benchMark.label}
                  </NavItem>
                ))}
            </NavLeft>
          </NavBox>

          <NavBox>
            <StyledText bold>{t["molecules:ThirdHeader:periodo"]}</StyledText>
            <NavMiddle style={{ border: "none" }}>
              {periods &&
                periods.map((period, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={selectedPeriod === period}
                    onClick={handlePeriodClick}
                  >
                    {t[`period:${period}`]}
                  </NavItem>
                ))}
            </NavMiddle>
          </NavBox>

          {selectedTab !== 0 && <NavBox style={{ width: "265px" }}></NavBox>}
        </>
      ) : (
        <>
          <NavBox>
            <StyledText bold>{t["molecules:ThirdHeader:benchmark"]}</StyledText>
            <NavLeft style={{ border: "none" }}>
              {benchmarkAssets &&
                benchmarkAssets.map((benchMark, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={benchMarkIsSelected(index)}
                    onClick={handleBenchmarkClick}
                  >
                    {benchMark.label}
                  </NavItem>
                ))}
            </NavLeft>
          </NavBox>

          <Period
            selectedPeriod={selectedPeriod}
            periods={periods}
            setSelectedPeriod={setSelectedPeriod}
            selectedTab={selectedTab}
          />

          {selectedTab === 0 && (
            <InitialApplication
              initialApplication={initialApplication}
              setInitialApplication={setInitialApplication}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ThirdHeader;
