import styled from "styled-components";
import { Div } from "components";

export const Wrapper = styled.li`
  margin-top: 22px;
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  display: flex;
  min-width: 245px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: white;
`;

export const ColorFlag = styled(Div)`
  min-width: 4px;
  align-self: stretch;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export const ButtonContainer = styled(Div)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;
