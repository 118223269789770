import React, { useEffect, useState } from "react";
import { AgGridColumn } from "ag-grid-react";
import { useMyContext } from "../../../hooks";
import { Acordion, ActiveBox } from "components";
import { StyledAgGrid } from "../AcordionTable/styles";
import {
  AssetColumHeader,
  CustomHeader,
  getRowNodeId,
  lastRowBorder,
} from "../AcordionTable/index";
import { OverlayRow } from "components";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const AcordionTableIndicators = ({
  title,
  assetsData,
  changeAssetColor,
  removeAsset,
  selectedTab,
  columns,
}) => {
  const { t } = useMyContext();

  const valueFormatter = (params, key) => {
    switch (selectedTab) {
      case 0:
        return params.data.indicatorsRentabilityData[key].value;
      case 1:
        return params.data.indicatorsReturnData[key].value;
      case 2:
        return params.data.indicatorsDrawdownData[key].value;
      default:
        return null;
    }
  };

  const getPeriod = (key) => {
    switch (selectedTab) {
      case 0:
        return { period: assetsData[0].indicatorsRentabilityData[key].period };
      case 1:
        return { period: assetsData[0].indicatorsReturnData[key].period };
      case 2:
        return { period: assetsData[0].indicatorsDrawdownData[key].period };
      default:
        return null;
    }
  };

  return (
    <Acordion title={title}>
      {assetsData.length > 0 && (
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <StyledAgGrid
            rowData={assetsData}
            frameworkComponents={{
              activeBox: ActiveBox,
              overlayRow: OverlayRow,
              customHeader: CustomHeader,
              assetColumnHeader: AssetColumHeader,
            }}
            domLayout={"autoHeight"}
            defaultColDef={{
              flex: 1,
              sortable: true,
            }}
            getRowNodeId={getRowNodeId}
            getRowStyle={lastRowBorder}
          >
            <AgGridColumn
              pinned="left"
              lockPinned={true}
              lockPosition={true}
              cellClass="lock-pinned"
              cellStyle={{ padding: 0, alignSelf: "center" }}
              headerName={t["common:asset"]}
              cellRenderer="activeBox"
              headerComponent="assetColumnHeader"
            />
            {columns.map((column, index) => (
              <AgGridColumn
                key={column}
                cellStyle={{
                  marginBottom: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight:
                    columns.length === index + 1
                      ? "none"
                      : "1px solid rgba(51, 51, 51, 0.4)",
                }}
                field={column}
                lockPosition={true}
                width={81}
                minWidth={81}
                headerClass={
                  columns.length === index + 1 ? "last-column-header" : ""
                }
                valueFormatter={(params) => valueFormatter(params, column)}
                headerComponent="customHeader"
                headerComponentParams={{
                  ...getPeriod(column),
                  customDisplayName: column,
                }}
              />
            ))}
            <AgGridColumn
              pinned="right"
              lockPinned={true}
              cellStyle={{ padding: 0, alignSelf: "center" }}
              headerName="Delete Button"
              cellRenderer="overlayRow"
              cellRendererParams={{ changeAssetColor, removeAsset }}
            />
          </StyledAgGrid>
        </div>
      )}
    </Acordion>
  );
};

export default AcordionTableIndicators;
