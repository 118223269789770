import React from "react";

import { useMyContext } from "hooks";
import SearchAssetInfoItem from "../SearchAssetInfoItem";

const SearchFundInfo = ({
  cnpj,
  situation,
  managementCompany,
  wordsToHighlight,
}) => {
  const { t } = useMyContext();

  return (
    <>
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:cnpj"]}
        value={cnpj}
        wordsToHighlight={wordsToHighlight}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:situation"]}
        value={situation}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:managementCompany"]}
        value={managementCompany}
      />
    </>
  );
};

export default SearchFundInfo;
