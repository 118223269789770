import styled from "styled-components";
import { Div, Text, Button } from "components";

export const Wrapper = styled(Div)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const ActivesContainer = styled(Div)`
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
`;

export const StyledButton = styled(Button)`
  border: none;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #333;
  margin-left: 24px;
`;

export const StyledText = styled(Text)`
  font-weight: 700;
  @media (max-width: 920px) {
    color: white;
    font-weight: 600;
    padding-bottom: 13px;
  }
`;

export const TitleContainer = styled(Div)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 12px;
`;

export const SelectedAssetsContainer = styled(Div)`
  background-color: ${({ theme }) =>
    theme.colors[theme.background.sextenary.color][
      [theme.background.sextenary.intensity]
    ]};
  min-height: 360px;
  padding: 16px;
`;

export const SelectedAssetsList = styled.ul`
  list-style-type: none;
`;
