import React from "react";
import { ActiveBox } from "components";
import { StyledIconButton, StyledSpan } from "./styles";
import { useMyContext, useAssets } from "hooks";

const OverlayRow = ({ data, changeAssetColor, removeAsset }) => {
  const { t } = useMyContext();
  const { findBenchmarkAsset, selectedBenchmarksIndexes } = useAssets();
  const remove = () => {
    removeAsset(data.identifier, data.assetType);
  };

  const isLastBenchmark = () => {
    const benchmarkIndex = findBenchmarkAsset(data.identifier, data.assetType);

    return benchmarkIndex !== -1 && selectedBenchmarksIndexes.length === 1;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <ActiveBox data={data} changeAssetColor={changeAssetColor} />
      {!isLastBenchmark() && (
        <div style={{ display: "flex" }}>
          <StyledIconButton
            iconposition="left"
            iconname="delete"
            type="button"
            size="lg"
            onClick={remove}
          >
            <StyledSpan>{t["organisms:OverlayRow:remover"]}</StyledSpan>
          </StyledIconButton>
        </div>
      )}
    </div>
  );
};

export default OverlayRow;
