import translations from "i18n/LanguageProvider/locales";
const Utils = () => {
  const locale = localStorage.getItem("locale") || navigator.language;
  const currency = localStorage.getItem("currency") || "BRL";

  const t = translations[locale];
  const months = [
    t["month:0"],
    t["month:1"],
    t["month:2"],
    t["month:3"],
    t["month:4"],
    t["month:5"],
    t["month:6"],
    t["month:7"],
    t["month:8"],
    t["month:9"],
    t["month:10"],
    t["month:11"],
  ];
  const shortMonths = [
    t["shortMonth:0"],
    t["shortMonth:1"],
    t["shortMonth:2"],
    t["shortMonth:3"],
    t["shortMonth:4"],
    t["shortMonth:5"],
    t["shortMonth:6"],
    t["shortMonth:7"],
    t["shortMonth:8"],
    t["shortMonth:9"],
    t["shortMonth:10"],
    t["shortMonth:11"],
  ];

  const date2DigitsFormatter = new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const dateShortFormatter = new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  const currencyFormatOptions = {
    ...currencyFormatter.resolvedOptions(),
    currencySymbol: currencyFormatter.formatToParts()[0].value,
  };

  const numberFormatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const dateUTC = (date, utc) => {
    const dt = new Date(date);
    return new Date(dt.setHours(dt.getHours() + utc));
  };

  const monthNameForDate = (date) => {
    const newDate = dateUTC(date, 3);
    const monthIndex = newDate.getMonth();
    return months[monthIndex];
  };

  const shortMonthNameForDate = (date) => {
    const newDate = new Date(date);
    const monthIndex = newDate.getMonth();
    return shortMonths[monthIndex];
  };

  const yearForDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString();
    return year.substr(2, 2);
  };

  const fullYearForDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString();
    return year;
  };

  const formatDate = (date) => {
    const newDate = dateUTC(date, 3);
    return dateShortFormatter.format(newDate);
  };

  const formatPeriod = (finalDate, initialDate) => {
    let initialD = initialDate && new Date(initialDate);
    let finalD = finalDate && new Date(finalDate);
    //TODO: refact + 3
    initialD = initialD && initialD.setHours(initialD.getHours() + 3);
    finalD = finalD.setHours(finalD.getHours() + 3);

    return initialD
      ? `${date2DigitsFormatter.format(initialD)} ${
          t["period:to"]
        }  ${date2DigitsFormatter.format(finalD)}`
      : `${date2DigitsFormatter.format(finalD)}`;
  };

  const fillZero = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const formatCurrency = (value) => currencyFormatter.format(value);

  const formatPercent = (value) => `${formatNumber(value)}%`;

  const formatNumber = (value) => numberFormatter.format(value);

  const formatValue = (value, format) => {
    switch (format) {
      case "CURRENCY":
        return formatCurrency(value);
      case "PERCENTAGE":
        return formatPercent(value);
      case "NUMBER":
      default:
        return formatNumber(value);
    }
  };

  const removeDiacritics = (string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  return {
    monthNameForDate,
    shortMonthNameForDate,
    yearForDate,
    fullYearForDate,
    formatDate,
    fillZero,
    formatPeriod,
    formatCurrency,
    formatPercent,
    formatNumber,
    removeDiacritics,
    date2DigitsFormatter,
    currencyFormatOptions,
    formatValue,
  };
};

export default Utils;
