import React, { useState, useEffect } from "react";

import {
  GraphicTemplate,
  SearchHeader,
  SecondHeader,
  ThirdHeader,
  FooterGraphicPage,
  SwiperPagination,
  SerieModal,
  AcordionTableIndexPeriod,
  AcordionTableReturnHistory,
  LoadingMask,
  ShareWhatsAppButton,
} from "components";
import HigchartsBase from "components/Highcharts/base";
import HighchartsCorrelation from "components/Highcharts/correlation";
import { useMyContext, useAssets } from "hooks";
import { ReduxStatePane } from "../../organisms/ReduxStatePane";
import {
  useSelectPeriod,
  useSelectInicialApplication,
  useSelectTab,
  useSelectBenchmarksIndexes,
} from "../../../state";
import { useParams } from "react-router-dom";
import { urlParser } from "../../../utils/urlParser";
import { INITIAL_BENCHMARK_INDEX } from "models/constants";
import LinkErrorModal from "components/molecules/Modals/LinkErrorModal";
import LinkLoadingModal from "components/molecules/Modals/LinkLoadingModal";

const GraphicPage = () => {
  const {
    assets,
    updateWhenChangesPeriodOrInitialApplication,
    changeAssetColor,
    removeAsset,
    isLoadingAssets,
    addAssetsBatch,
    benchmarkAssets,
  } = useAssets();

  const { urlData } = useParams();
  const configFromUrl = urlData && urlParser(urlData);

  const [selectedPeriod, setSelectedPeriod] = useSelectPeriod();
  const [isLoading, setIsLoading] = useState(true);
  const { t, history } = useMyContext();
  const { innerWidth } = window;
  const [initialAssets, setInitialAssets] = useState(
    history.location.state && history.location.state.selectedAssets
      ? [...history.location.state.selectedAssets]
      : []
  );

  const [indicatorsRentabilityKeys, setIndicatorsRentabilityKeys] = useState(
    []
  );
  const [indicatorsReturnKeys, setIndicatorsReturnKeys] = useState([]);
  const [indicatorsDrawdownKeys, setIndicatorsDrawdownKeys] = useState([]);

  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();
  const [selectedTab, setSelectedTab] = useSelectTab();

  const [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes] =
    useSelectBenchmarksIndexes();

  const [linkErrorModal, setLinkErrorModal] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  useEffect(() => {
    if (configFromUrl !== null) {
      configFromUrl && setInitialApplication(configFromUrl.initialAmount);
      configFromUrl && setSelectedPeriod(configFromUrl.period);
      configFromUrl && setSelectedTab(Number(configFromUrl.tab));
      configFromUrl &&
        setSelectedBenchmarksIndexes([
          ...configFromUrl.benchmarks.map((bench) => Number(bench)),
        ]);
    }
  }, []);

  useEffect(() => {
    if (assets.length > 0) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const updateInitialAssetsEffect = async () => {
      if (benchmarkAssets.length > 0) {
        const isShareLink = !(
          configFromUrl === undefined || configFromUrl === null
        );
        setLinkLoading(isShareLink);

        setIsLoading(true);

        const alreadyHasBenchmarks =
          initialAssets.filter(({ assetType }) => assetType === "INDICE")
            .length > 0;

        const initial = !isShareLink
          ? alreadyHasBenchmarks
            ? initialAssets
            : [benchmarkAssets[INITIAL_BENCHMARK_INDEX], ...initialAssets]
          : [
              ...selectedBenchmarksIndexes.map(
                (x) => benchmarkAssets[Number(x)]
              ),
              ...initialAssets,
              ...configFromUrl.assets,
            ];

        await addAssetsBatch(
          initial,
          selectedPeriod,
          initialApplication,
          // Manter período, mesmo com ativos sem séries
          !isShareLink
        );
        configFromUrl === null && setLinkErrorModal(true);
        setIsLoading(false);
        setLinkLoading(false);
      }
    };
    updateInitialAssetsEffect();
  }, [initialAssets, benchmarkAssets]);

  useEffect(() => {
    const updatePeriodEffect = async () => {
      await updateWhenChangesPeriodOrInitialApplication(
        selectedPeriod,
        initialApplication
      );
    };
    updatePeriodEffect();
  }, [selectedPeriod]);

  useEffect(() => {
    const updateinitialApplicationEffect = async () => {
      await updateWhenChangesPeriodOrInitialApplication(
        selectedPeriod,
        initialApplication
      );
    };
    updateinitialApplicationEffect();
  }, [initialApplication]);

  useEffect(() => {
    const updateAssetsEffect = async () => {
      if (assets.length > 0) {
        if (assets[0]?.indicatorsRentabilityData)
          setIndicatorsRentabilityKeys(
            Object.keys(assets[0].indicatorsRentabilityData)
          );
        if (assets[0]?.indicatorsReturnData)
          setIndicatorsReturnKeys(Object.keys(assets[0].indicatorsReturnData));
        if (assets[0]?.indicatorsDrawdownData)
          setIndicatorsDrawdownKeys(
            Object.keys(assets[0].indicatorsDrawdownData)
          );
      }
    };
    updateAssetsEffect();
  }, [assets]);

  const getIndicatorsColumns = () => {
    switch (selectedTab) {
      case 0:
        return indicatorsRentabilityKeys;
      case 1:
        return indicatorsReturnKeys;
      case 2:
        return indicatorsDrawdownKeys;
      default:
        return null;
    }
  };

  const getTypeSwiper = () => {
    switch (selectedTab) {
      case 0:
        return "indicatorsRentability";
      case 1:
        return "indicatorsReturn";
      case 2:
        return "indicatorsDrawdown";
      default:
        return null;
    }
  };

  return (
    <>
      <GraphicTemplate
        header={
          <SearchHeader
            period={selectedPeriod}
            initialApplication={initialApplication}
          />
        }
        secondHeader={
          <SecondHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        }
        thirdHeader={
          <ThirdHeader
            setSelectedPeriod={setSelectedPeriod}
            selectedPeriod={selectedPeriod}
            initialApplication={initialApplication}
            setInitialApplication={setInitialApplication}
            selectedTab={selectedTab}
          />
        }
        footer={<FooterGraphicPage />}
      >
        <>
          {linkLoading && <LinkLoadingModal />}
          {(isLoadingAssets || isLoading) && <LoadingMask />}
          {selectedTab !== 3 ? (
            <>
              <HigchartsBase assets={assets} selectedTab={selectedTab} />
              {innerWidth <= 920 ? (
                <>
                  <SwiperPagination
                    assetsData={assets}
                    type={getTypeSwiper()}
                  />
                  {getTypeSwiper() !== "indicatorsDrawdown" && (
                    <SwiperPagination assetsData={assets} type="history" />
                  )}
                </>
              ) : (
                <>
                  <AcordionTableIndexPeriod
                    title={t["table:Header:Indicators"]}
                    assetsData={assets}
                    columns={getIndicatorsColumns()}
                    changeAssetColor={changeAssetColor}
                    removeAsset={removeAsset}
                    selectedTab={selectedTab}
                  />
                  {selectedTab !== 2 && (
                    <AcordionTableReturnHistory
                      title={t["table:Header:ReturnHistory"]}
                      assetsData={assets}
                      changeAssetColor={changeAssetColor}
                      removeAsset={removeAsset}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <HighchartsCorrelation
              assets={assets}
              selectedTab={selectedTab}
              selectedPeriod={selectedPeriod}
              selectedBenchmarksIndexes={selectedBenchmarksIndexes}
            />
          )}
          <ShareWhatsAppButton />
        </>
        <SerieModal />

        <ReduxStatePane />
      </GraphicTemplate>
      <LinkErrorModal
        isOpen={linkErrorModal}
        body={t["share:invalidLink"]}
        onClose={() => setLinkErrorModal(false)}
      />
    </>
  );
};

export default GraphicPage;
