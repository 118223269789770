import React from "react";

import { useMyContext, useAssets } from "hooks";
import { Img } from "components";
import { Container } from "./styles";

import {
  useSelectPeriod,
  useSelectInicialApplication,
  useSelectTab,
  useSelectBenchmarksIndexes,
} from "../../../state";
import {
  generateSharedLink,
  getCurrentUrlBase,
} from "../../../utils/urlParser";
import { WHATSAPP_API_URL } from "models/constants";

import shareWhatsAppIcon from "../../../assets/shareWhatsApp.svg";

const ShareWhatsAppButton = ({ ...params }) => {
  const { t } = useMyContext();

  const [initialApplication] = useSelectInicialApplication();
  const [selectedTab] = useSelectTab();
  const [selectedBenchmarksIndexes] = useSelectBenchmarksIndexes();
  const [selectedPeriod] = useSelectPeriod();
  const { assets } = useAssets();

  const clientURL = getCurrentUrlBase();

  const handleOnClick = () => {
    const sharedLinkURL = generateSharedLink(
      initialApplication,
      selectedTab,
      selectedBenchmarksIndexes,
      selectedPeriod,
      assets
    );

    if (sharedLinkURL !== null) {
      const shareWindow = window.open("", "_blank");
      shareWindow.location.href =
        WHATSAPP_API_URL + encodeURIComponent(clientURL + sharedLinkURL);
    }
  };

  return (
    <Container {...params} onClick={handleOnClick}>
      <Img src={shareWhatsAppIcon} height="18px" width="18px" />
      {t["share:shareLink"]}
    </Container>
  );
};

export default ShareWhatsAppButton;
