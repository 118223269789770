import React, { useState, useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import { useMyContext } from "../../../hooks";
import { Acordion, ActiveBox } from "components";
import { StyledAgGrid } from "../AcordionTable/styles";
import {
  AssetColumHeader,
  CustomHeader,
  lastRowBorder,
} from "../AcordionTable/index";
import OverlayRow from "../OverlayRow";
import Utils from "utils/utils";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const AcordionTableReturnHistory = ({
  title,
  assetsData,
  changeAssetColor,
  removeAsset,
}) => {
  const [keys, setKeys] = useState([]);

  const { t } = useMyContext();
  const { formatPercent } = Utils();

  useEffect(() => {
    if (assetsData.length > 0) {
      setKeys(Object.keys(assetsData[0].returnHistoryData));
    }
  }, [assetsData]);

  const getRowNodeId = (data) => {
    return data.id;
  };

  const fomartValue = ({ data, colDef }) => {
    const value = data.returnHistoryData[colDef.field];
    return value ? formatPercent(value) : "n/a";
  };

  return (
    <Acordion title={title}>
      {assetsData.length > 0 && (
        <div
          className="ag-theme-alpine"
          style={{ width: "100%", border: "none" }}
        >
          <StyledAgGrid
            rowData={assetsData}
            frameworkComponents={{
              activeBox: ActiveBox,
              overlayRow: OverlayRow,
              assetColumnHeader: AssetColumHeader,
              customHeader: CustomHeader,
            }}
            domLayout={"autoHeight"}
            defaultColDef={{
              flex: 1,
              sortable: true,
            }}
            getRowNodeId={getRowNodeId}
            getRowStyle={lastRowBorder}
          >
            <AgGridColumn
              pinned="left"
              lockPinned={true}
              lockPosition={true}
              cellClass="lock-pinned"
              cellStyle={{ padding: 0, alignSelf: "center" }}
              headerName={t["common:asset"]}
              cellRenderer="activeBox"
              headerComponent="assetColumnHeader"
            />
            {keys.map((key, index) => (
              <AgGridColumn
                key={key}
                cellStyle={{
                  marginBottom: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight:
                    keys.length === index + 1
                      ? "none"
                      : "1px solid rgba(51, 51, 51, 0.4)",
                }}
                field={key}
                lockPosition={true}
                width={81}
                minWidth={81}
                headerClass={
                  keys.length === index + 1 ? "last-column-header" : ""
                }
                valueFormatter={fomartValue}
                headerComponent="customHeader"
              />
            ))}
            <AgGridColumn
              pinned="right"
              lockPinned={true}
              cellStyle={{ padding: 0, alignSelf: "center" }}
              headerName="Delete Button"
              cellRenderer="overlayRow"
              cellRendererParams={{ changeAssetColor, removeAsset }}
            />
          </StyledAgGrid>
        </div>
      )}
    </Acordion>
  );
};

export default AcordionTableReturnHistory;
