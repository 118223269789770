// import logo from 'assets/logo-oui-investimentos.png'
import logo from "assets/logo.svg";
// import logoForDarkTheme from 'assets/logo-quantum.png';
import logoForDarkTheme from "assets/logoDark.svg";

import imageBackground from "assets/imageHomeBackground1.svg";
import imageBackground2 from "assets/imageHomeBackground2.svg";
import imageBackground3 from "assets/imageHomeBackground3.svg";

const genericDesign = {
  backgroundImages: [imageBackground, imageBackground2, imageBackground3],
  fonts: {
    primary: "Open Sans, sans-serif",
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
    quote: "Georgia, serif",
    size: {
      sm: "12px",
      md: "14px",
      lg: "16px",
    },
    lineHeight: ["40px", "16px", "24px", "14px"],
    weight: ["400", "700", "600"],
  },
  colors: {
    white: [
      "#FFFFFF",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
      "#FAFAFA",
    ],
    black: [
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
      "#111122",
    ],
    gray: ["#333333", "#666666", "#F2F1F6", "#dce2eb"],
    red: ["#DF0B0B"],
    blue: ["#364E63", "#364E63", "#254057", "#3A5267", "#27618F", "#08263F"],
  },
  sizes: {
    pagesBreakpoints: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xlg: "1200px",
    },
    maxWidth: "1100px",
    title: {
      size: ["32px", "18px"],
      lineHeight: ["40px", "30px"],
      weight: ["400", "700"],
    },
    button: {
      sm: {
        padding: "8px 16px 8px 16px",
        justIconPadding: "8px",
        size: "12px",
        radius: "12px",
        width: "67px",
        height: "32px",
        lineHeight: "16px",
        letterSpacing: "0.04em",
      },
      md: {
        padding: "16px",
        justIconPadding: "14px",
        size: "14px",
        radius: "16px",
        width: "86px",
        height: "48px",
        lineHeight: "16px",
        letterSpacing: "0.02em",
      },
      lg: {
        padding: "16px 24px 16px 24px",
        justIconPadding: "18px 26px 18px 26px",
        size: "16px",
        radius: "16px",
        width: "93px",
        height: "56px",
        lineHeight: "24px",
        letterSpacing: "0.03em",
      },
    },
    icon: {
      xlg: "3em",
      lg: "2.5em",
      md: "2em",
      sm: "1.5em",
      xsm: "1em",
    },
    spacing: [
      "0px",
      "8px",
      "16px",
      "24px",
      "32px",
      "40px",
      "48px",
      "56px",
      "64px",
      "72px",
      "80px",
    ],
  },
};

const darkLight = {
  dark: {
    logo: {
      img: logoForDarkTheme,
      height: "38px",
      padding: "10px 0px",
      align: "center",
      show: true,
    },
    primary: [5, 7, 9],
    borders: [0],
    text: [0, 1],
    textColors: {
      primary: {
        color: "white",
        intensity: 0,
      },
      secondary: {
        color: "gray",
        intensity: 0,
      },
      error: { color: "red", intensity: 0 },
    },
    defaulTextColor: "black",
    header: {
      align: "space-between",
      first: {
        showBorder: false,
      },
      second: {
        margin: 0,
      },
      third: {
        margin: 0,
      },
    },
    background: {
      primary: { color: "gray", intensity: 1 },
      secondary: { color: "white", intensity: 0 },
      tertiary: { color: "blue", intensity: 2 },
      quaternary: { color: "blue", intensity: 5 },
      quintenary: { color: "gray", intensity: 3 },
      sextenary: { color: "blue", intensity: 2 },
    },
    iconColor: {
      primary: {
        color: "blue",
        intensity: 4,
      },
    },
    input: {
      placeholder: {
        primary: {
          color: "gray",
          intensity: 0,
        },
      },
      size: ["16px"],
      lineHeight: ["24px"],
      weight: ["400"],
      background: { color: "blue", intensity: 1 },
      textcolor: {
        color: "gray",
        intensity: 0,
      },
      bordercolors: {
        primary: {
          color: "gray",
          intensity: 8,
        },
        hover: {
          color: "blue",
          intensity: 4,
        },
        focus: {
          color: "blue",
          intensity: 4,
        },
        error: {
          color: "red",
          intensity: 2,
        },
        success: {
          color: "gray",
          intensity: 0,
        },
        disabled: {
          color: "gray",
          intensity: 3,
        },
      },
    },
    pages: {
      graphic: {
        paddingTop: 0,
      },
    },
    searchAssets: {
      width: "100%",
      padding: "24px 16px !important",
      marginTop: "10px",
      marginLeft: 0,
      listResult: {
        width: "inherit",
      },
    },
    selectedAssets: {
      show: false,
      viewButton: false,
    },
    showAssetFullName: false,
  },
  light: {
    logo: {
      img: logo,
      height: "38px",
      padding: "15px 0",
      align: "flex-start",
      show: true,
    },
    primary: [5, 7, 9],
    borders: [0],
    text: [0, 1],
    textColors: {
      primary: {
        color: "gray",
        intensity: 0,
      },
      secondary: { color: "white", intensity: 1 },
      error: { color: "red", intensity: 0 },
    },
    defaulTextColor: "black",
    background: {
      primary: { color: "gray", intensity: 1 },
      secondary: { color: "white", intensity: 0 },
      tertiary: { color: "blue", intensity: 2 },
      quaternary: { color: "gray", intensity: 2 },
      quintenary: { color: "gray", intensity: 3 },
      sextenary: { color: "white", intensity: 0 },
    },
    header: {
      align: "space-between",
      first: {
        showBorder: true,
      },
      second: {
        margin: 0,
      },
      third: {
        margin: "16px 0",
      },
    },
    iconColor: {
      primary: {
        color: "blue",
        intensity: 4,
      },
    },
    input: {
      placeholder: {
        primary: {
          color: "gray",
          intensity: 0,
        },
      },
      size: ["16px"],
      lineHeight: ["24px"],
      weight: ["400"],
      background: { color: "blue", intensity: 1 },
      textcolor: {
        color: "gray",
        intensity: 0,
      },
      bordercolors: {
        primary: {
          color: "gray",
          intensity: 8,
        },
        hover: {
          color: "blue",
          intensity: 4,
        },
        focus: {
          color: "blue",
          intensity: 4,
        },
        error: {
          color: "red",
          intensity: 2,
        },
        success: {
          color: "gray",
          intensity: 0,
        },
        disabled: {
          color: "gray",
          intensity: 3,
        },
      },
    },
    pages: {
      graphic: {
        paddingTop: 0,
      },
    },
    searchAssets: {
      width: "100%",
      padding: 0,
      marginLeft: "40px",
      marginTop: 0,
      listResult: {
        width: "inherit",
      },
    },
    selectedAssets: {
      show: false,
      viewButton: false,
    },
    showAssetFullName: false,
  },
};

// eslint-disable-next-line
export default {
  dark: {
    ...darkLight.dark,
    ...genericDesign,
  },
  light: {
    ...darkLight.light,
    ...genericDesign,
  },
};
