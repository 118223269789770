import React from "react";

import { useMyContext } from "hooks";
import SearchAssetInfoItem from "../SearchAssetInfoItem";

const SearchFixedIncomeInfo = ({ type, situation }) => {
  const { t } = useMyContext();

  return (
    <>
      {type && (
        <SearchAssetInfoItem
          label={t["organisms:SearchAssets:type"]}
          value={type}
        />
      )}

      {situation && (
        <SearchAssetInfoItem
          label={t["organisms:SearchAssets:situation"]}
          value={situation}
        />
      )}
    </>
  );
};

export default SearchFixedIncomeInfo;
