import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Wrapper, ContentWrapper, StyledHeader } from "./styles";
import { Footer } from "components";
import { useMyContext } from "hooks";

const MainTemplate = ({ header, children, footer }) => {
  const { setThemeType } = useMyContext();
  const { innerWidth } = window;

  useEffect(() => {
    if (innerWidth <= 920) {
      setThemeType("dark");
    }
  }, []);

  return (
    <Wrapper id="MainTemplate">
      <StyledHeader showBorder>{header}</StyledHeader>
      <ContentWrapper>{children}</ContentWrapper>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.any.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default MainTemplate;
