import styled from "styled-components";
import { Div, Header } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${({ theme }) => theme.pages.graphic.paddingTop};
  background-color: ${({ theme }) =>
    theme.colors[theme.background.quaternary.color][
      [theme.background.quaternary.intensity]
    ]};
`;

export const FirstHeader = styled(Header)``;

export const SecondHeader = styled(Header)`
  border-bottom: ${({ theme }) => theme.header.second.borderBottom};
  margin: ${({ theme }) => theme.header.second.margin};
`;

export const ThirdHeader = styled(Header)`
  border: ${({ theme }) => theme.header.third.border};
  border-bottom: ${({ theme }) => theme.header.third.borderBottom};
  margin: ${({ theme }) => theme.header.third.margin};
`;

export const Content = styled(Div)``;
