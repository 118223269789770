import { initialState } from "./index";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    case "SET_ASSETS_WITHOUT_SERIES":
      return {
        ...state,
        assetsWithoutSeries: action.payload,
      };
    case "SET_PERIOD":
      return {
        ...state,
        selectedPeriod: action.payload,
      };
    case "SET_INITIAL_APPLICATION":
      return {
        ...state,
        initialApplication: action.payload,
      };
    case "SET_TAB":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "SET_BENCHMARKS":
      return {
        ...state,
        selectedBenchmarksIndexes: [...new Set(action.payload)],
      };
    case "SET_CORRELATION_DATA":
      return {
        ...state,
        correlationData: action.payload,
      };
    case "SET_BENCHMARKS_ASSETS":
      return {
        ...state,
        benchmarkAssets: action.payload,
      };
    case "SET_PERIOD_RANGE":
      return {
        ...state,
        periodRange: action.payload,
      };
    case "SET_SHARED_LINK":
      return {
        ...state,
        sharedLink: action.payload,
      };
    case "FETCHING_ASSETS_SERIES":
      return {
        ...state,
        isFetchingAssetsSeries: action.payload,
      };

    default:
      return state;
  }
};
