import styled from "styled-components";
import { Div, Img } from "components";

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 578px;
  padding: 29px 21px 13px 21px;

  @media (max-width: 920px) {
    max-width: 310px;
    padding: 17px 17px 12px 17px;
  }
`;

export const Header = styled(Div)`
  display: flex;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const StyledImg = styled(Img)`
  margin-right: 24px;
  margin-left: 16px;

  @media (max-width: 920px) {
    margin-right: 0px;
    margin-bottom: 11px;
  }
`;

export const AlertText = styled.strong`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;

  @media (max-width: 920px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Body = styled(Div)`
  min-height: 73px;
  background-color: rgba(238, 240, 242, 0.5);
  display: flex;
  flex-direction: column;
  padding: 8px;

  span {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 920px) {
    span {
      font-size: 12px;
    }
  }
`;

export const Footer = styled(Div)`
  margin-top: 11px;
  display: flex;
  justify-content: flex-end;

  button {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 0px 24px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: ${({ theme }) =>
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]};
    cursor: pointer;
  }

  @media (max-width: 920px) {
    justify-content: center;
  }
`;
